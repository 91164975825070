import React from 'react'
import NavbarJogador from './navbarJogador'

export default props => (
	<nav className="main-header navbar navbar-expand navbar-light navbar-white border-bottom-0" style={{ backgroundColor: '#0D1B2A' }}>
		<ul className="navbar-nav">
			<li className="nav-item">
				<a className="nav-link" data-widget="pushmenu" href="#" role="button" style={{ color: '#fff' }}><i className="fas fa-bars"></i></a>
			</li>
		</ul>
		<NavbarJogador />
	</nav>
)
