const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
    listaEsporte: [],
    listaCategoriaTipo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CATEGORIA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'CATEGORIA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'CATEGORIA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

            
        case 'CATEGORIA_ESPORTE_SELECT_LISTADO':
            return { ...state,
                listaEsporte: action.payload.data
             };

         case 'CATEGORIA_TIPO__SELECT_LISTADO':
            return { ...state,
                listaCategoriaTipo: action.payload.data
            };
        default:
            return state;
    }
}
