import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardJogadorActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';

import Form from './dashboardAgendaForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaEsporte, getListaProfessor, getListaQuadra, getListaHoras, getListaUsuario, getListaHorariosAgendados } from './dashboardJogadorActions';

import imagemBanner from '../assets/images/banner.png';

class DashboardJogadorHorarios extends Component {

	state = {

	}

	constructor(props) {
		super(props);
    }

    componentWillMount() {
		this.props.getLista(this.props.sessao.id);
    }

    render() {

		let diaSemana = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

        return (
			<Content>
				{this.props.modoTela == 'cadastro' ? (
					<Form onSubmit={this.props.salvar} />
				) : null}
				{this.props.modoTela == 'exclusao' ? (
					<Form excluir onSubmit={this.props.excluir} />
				) : null}
				{this.props.modoTela == 'lista' ? (
					<Row>
						{this.props.lista.filter(item => !item.nome_quadra.includes('Churrasqueira')).filter(item => {
							if (item.fixo) {
								return this.props.lista.filter(ant => ant.id == item.id_agenda_anterior).length == 0;
							} else {
								return true;
							}
						}).map(item => (
							<Grid key={item.id} cols='12 6 6 4'>
								<ContentCard style={{ backgroundColor: item.nome_quadra.includes('Churrasqueira') ? '#0d1b2a' : (item.id_esporte == 7 ? '#415a77' : '#778da9') }}>
									<ContentCardBody>
										<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 300, padding: 20 }}>
											<h4 style={{ color: '#fff' }}>{item.nome_quadra}</h4>
											<h5 style={{ color: '#fff' }}>{item.nome_quadra.includes('Churrasqueira') ? 'Churrasqueira' : item.nome_esporte}</h5>
											<h5 style={{ color: '#fff' }}>{item.aula ? 'Aula' : 'Jogo'}{item.aula ? ' com ' : ''}{item.nome_professor}{item.fixo ? ' - FIXO' : ''}</h5>
											<h6 style={{ color: '#fff' }}>{item.dia_semana} - {item.data_formatada} - {(item.horarios || []).join(', ')}</h6>
											<h5 style={{ color: '#fff' }}>Valor: R$ {parseFloat(item.total || 0).toFixed(2).replace('.', ',')}</h5>
											<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'baseline' }}>
												<button class='btn btn-block btn-lg btn-danger'
													style={{ height: 50 }}
													onClick={() => {
														this.props.setModoTela('exclusao', item);
														this.props.initForm(item);
													}}>Remover</button>
											</div>
										</div>
									</ContentCardBody>
								</ContentCard>
							</Grid>
						))}
					</Row>
				) : null}
			</Content>
        )
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.dashboardJogador.modoTela,
	listaQuadra: state.dashboardJogador.listaQuadra,
	lista: state.dashboardJogador.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, getLista, getListaEsporte, getListaProfessor, getListaQuadra, getListaHoras, getListaHorariosAgendados
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardJogadorHorarios)
