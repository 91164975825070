import React, { Component } from 'react';
import MenuItem from './menuItem';
import MenuTree from './menuTree';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Menu extends Component {
	state = {
		cadastrosAberto: false
	};
	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;

		}
		if (!this.state.cadastrosAberto && (selecionado == '/categoria')) {
			this.setState({ ...this.state, cadastrosAberto: true });
		}

		if (this.props.sessao && this.props.sessao.id_professor && (
			selecionado == '/usuario' || selecionado == '/usuarioJogador' || selecionado == '/esporte'
			|| selecionado == '/professor' || selecionado == '/quadra')) {

			window.location = '/';
		}

		let acessoProfessor = this.props.sessao && this.props.sessao.id_professor;

		return (
			<nav className="mt-2">
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
			        <MenuItem path='/' label='Agenda' icon='fas fa-calendar-alt' active={selecionado == '/'} />
					{!acessoProfessor ?
						<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
					: null}
					{!acessoProfessor ?
						<MenuItem path='usuarioJogador' label='Jogador' icon='far fa-id-badge' active={selecionado == '/jogador'} />
					: null}
					{!acessoProfessor ?
						<MenuItem path='esporte' label='Esporte' icon='fas fa-table-tennis' active={selecionado == '/esporte'} />
					: null}
					{!acessoProfessor ?
						<MenuItem path='professor' label='Professor' icon='fas fa-user-graduate' active={selecionado == '/professor'} />
					: null}
					{!acessoProfessor ?
						<MenuItem path='quadra' label='Quadra' icon='fas fa-border-all' active={selecionado == '/quadra'} />
					: null}
					<MenuItem path='classificacao' label='Ranking' icon='fas fa-trophy' active={selecionado == '/classificacao'} />
					{!acessoProfessor ?
						<MenuItem path='produto' label='Produto' icon='fas fa-table' active={selecionado == '/produto'} />
					: null}
					{!acessoProfessor ?
						<MenuItem path='comanda' label='Comanda' icon='fas fa-money-check-alt' active={selecionado == '/comanda'} />
					: null}
		    	</ul>
			</nav>
		);

	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
