import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardJogadorActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';

import Form from './dashboardAgendaForm';

import { setModoTela, initForm, salvar, excluir, isUsuarioAtivo, getLista, getListaEsporte, getListaProfessor, getListaQuadra, getListaHoras, getListaUsuario, getListaHorariosAgendados } from './dashboardJogadorActions';

import imagemBanner from '../assets/images/banner.png';
import adesivoBig from '../assets/images/adesivo_big.png';
import adesivoCorujao from '../assets/images/adesivo_corujao.png';
import adesivoL4 from '../assets/images/adesivo_l4.png';
import adesivoCoi from '../assets/images/adesivo_coi.png';
import adesivoHd from '../assets/images/adesivo_hd.png';
import adesivoPepsi from '../assets/images/adesivo_pepsi.png';
import adesivoTaubescar from '../assets/images/adesivo_taubescar.png';
import adesivoBickelkoh from '../assets/images/adesivo_bickelkoh.png';
import adesivoHonda from '../assets/images/adesivo_honda.png';

class DashboardJogador extends Component {

	state = {
		logos: [
			{ id_quadra: 2, logo: adesivoBig }, { id_quadra: 3, logo: adesivoTaubescar }, { id_quadra: 4, logo: adesivoL4 }, 
			{ id_quadra: 5, logo: adesivoCoi }, { id_quadra: 12, logo: adesivoHd }, { id_quadra: 13, logo: adesivoPepsi, cor: '#000' }, 
			{ id_quadra: 14, logo: adesivoCorujao }, { id_quadra: 19, logo: adesivoBickelkoh }, { id_quadra: 20, logo: adesivoHonda }]
	}

	constructor(props) {
		super(props);
    }

    componentWillMount() {
		this.props.getListaQuadra();
		this.props.isUsuarioAtivo(this.props.sessao.id);

		setInterval(() => {
			this.props.getListaQuadra();
			this.props.isUsuarioAtivo(this.props.sessao.id);
		}, 60000);
    }

    render() {

		if (!this.props.jogadorAtivo) {
			return (
				<Content>
					<Row>
						<Grid cols='12 12 12 12'>
							Usuário Bloqueado
						</Grid>
					</Row>
				</Content>
			);
		}

        return (
			<Content>
				{this.props.modoTela == 'cadastro' ? (
					<Form onSubmit={this.props.salvar} />
				) : null}
				{this.props.modoTela == 'exclusao' ? (
					<Form excluir onSubmit={this.props.excluir} />
				) : null}
				{this.props.modoTela == 'lista' ? (
					<Row>
						{this.props.listaQuadra.filter(item => !item.nome.includes('Churrasqueira') && !item.bloqueado).map(item => (
							<Grid key={item.id} cols='12 6 6 4'>
								<ContentCard style={{ backgroundColor: item.nome.includes('Churrasqueira') ? '#0d1b2a' : (item.id_esporte == 7 ? '#415a77' : '#778da9') }}>
									{this.state.logos.filter(logo => logo.id_quadra == item.id)[0] ?
										<div style={{ width: '100%', backgroundColor: this.state.logos.filter(logo => logo.id_quadra == item.id)[0].cor || '#fff', textAlign: 'center' }}>
											<img src={this.state.logos.filter(logo => logo.id_quadra == item.id)[0].logo}
										 		style={{ width: 200 }}/>
										</div>
									:
										<div style={{ width: '100%', height: 88, backgroundColor: '#fff', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
											<h4 style={{ color: '#415a77' }}>{item.nome}</h4>
										</div>
									}
									<ContentCardBody>
										<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: 300, padding: 20 }}>
											<h5 style={{ color: '#fff' }}>{item.nome.includes('Churrasqueira') ? 'Churrasqueira' : item.nome_esporte}</h5>
											<h6 style={{ color: '#fff' }}>{item.descricao}</h6>
											<h5 style={{ color: '#fff' }}>R$ {parseFloat(item.preco_unitario || 0).toFixed(2).replace('.', ',')} a cada {item.horario_unitario} minutos</h5>
											<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'baseline' }}>
												{/*
												<button class='btn btn-block btn-lg btn-success'
													style={{ height: 50 }}
													onClick={() => {
														this.props.setModoTela('cadastro', {});
														this.props.initForm({
															data_agenda: DateFormat.getDataAtual(),
															id_esporte: item.id_esporte,
															id_quadra: item.id,
															aula: true,
															id_usuario_jogador: this.props.sessao.id,
															cadastro_jogador: false,
															horarios: [],
															id_agenda_status: 1 //Agendado
														});
														this.props.getListaHorariosAgendados(DateFormat.getDataAtual());
														this.props.getListaProfessor();
													}}>Marcar Aula</button>*/}

												{item.id != 15 ? (
													<button class='btn btn-block btn-lg btn-success'
														style={{ height: 50 }}
														onClick={() => {
															this.props.setModoTela('cadastro', {});
															this.props.initForm({
																data_agenda: DateFormat.getDataAtual(),
																id_esporte: item.id_esporte,
																id_quadra: item.id,
																aula: false,
																id_usuario_jogador: this.props.sessao.id,
																cadastro_jogador: false,
																horarios: [],
																id_agenda_status: 1 //Agendado
															});
															this.props.getListaHorariosAgendados(DateFormat.getDataAtual());
															this.props.getListaProfessor();
														}}>Marcar Jogo</button>
													) : null}
											</div>
										</div>
									</ContentCardBody>
								</ContentCard>
							</Grid>
						))}
					</Row>
				) : null}
			</Content>
        )
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.dashboardJogador.modoTela,
	listaQuadra: state.dashboardJogador.listaQuadra,
	jogadorAtivo: state.dashboardJogador.jogadorAtivo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, isUsuarioAtivo, getLista, getListaEsporte, getListaProfessor, getListaQuadra, getListaHoras, getListaHorariosAgendados
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardJogador)
