const INITIAL_STATE = {
    registro: {},
    modoTela: 'lista',
    aguardando: false,
    lista: [],
    listaCompeticao: [],
    listaCompeticaoCategoria: [],
    listaCompeticaoImpedimento: [],

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'COMPETICAO_INSCRICAO_MODO_TELA':
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro
            };

        case 'COMPETICAO_INSCRICAO_LISTADO':
            return {
                ...state,
                lista: action.payload.data
            };

        case 'COMPETICAO_INSCRICAO_SET_AGUARDANDO':
            return {
                ...state,
                aguardando: action.payload
            };

        case 'COMPETICAO_CATEGORIA_COMPETICAO_SELECT_LISTADO':
            return {
                ...state,
                listaCompeticao: action.payload.data
            };
        
         case 'COMPETICAO_INSCRICAO_COMPETICAO_CATEGORIA_SELECT_LISTADO':
            return {
                ...state,
                listaCompeticaoCategoria: action.payload.data
            };
       
        case 'COMPETICAO_INSCRICAO_COMPETICAO_IMPEDIMENTO_SELECT_LISTADO':
            return {
                ...state,
                listaCompeticaoImpedimento: action.payload.data
            };

        default:
            return state;
    }
}
