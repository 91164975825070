import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
import { setError } from '../../common/toast/toast';
import LabelAndInputDate from '../../common/form/labelAndInputDate';



import {
    setModoTela, initForm, getListaCompeticaoCategoria
} from './competicaoInscricaoActions';
import labelAndInput from '../../common/form/labelAndInput';

class CompeticaoInscricaoForm extends Component {

    state = {

    }

    componentDidMount() {

        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.addEventListener("change", (data) => {

            let reader = new FileReader();
            let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;


        });
        this.fileSelector = fileSelector;

    }


    componentWillMount() {

    }

    render() {

        let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
            <ContentCard>
                <Form event={this.props.handleSubmit}>
                    <ContentCardBody>
                        <Row>
                            <Select
                                name='id_competicao'
                                
                                label='Competição' placeholder='Informe a competição'
                                cols='12 12 12 12'
                                readOnly={readOnly}
                                options={this.props.listaCompeticao}  
                                value= {this.props.formularioValues.id_competicao}
                                onChange ={(value) => { 
                                    this.props.initForm({
                                        ...this.props.formularioValues,id_competicao:value
                                    })
                                    this.props.getListaCompeticaoCategoria(value)
                                }} />
                            
                            <Field
                                name='id_categoria'
                                component={Select}
                                label='Categoria ' placeholder='Informe a categoria'
                                cols='12 12 12 12'
                                readOnly={readOnly}
                                options={this.props.listaCompeticaoCategoria} />


                            <Field
                                name='nome'
                                component={labelAndInput}
                                label='Nome' placeholder='Informe o nome'
                                cols='12 12 12 12'
                                readOnly={readOnly}
                                  />
                            
                            <Field
                                name='id_competicao_impedimento'
                                component={Select}
                                label='Impedimento' placeholder='Informe o impedimento'
                                cols='12 12 12 12'
                                readOnly={readOnly}
                                options={this.props.listaCompeticaoImpedimento}
                                  />
                            
                           
                            
                         </Row>
                    
                    </ContentCardBody>
                    <ContentCardFooter>
                        <Row alignCenter>
                            <Grid cols='6 6 4 3'>
                                {this.props.excluir ? (
                                    <Button
                                        text='Excluir'
                                        submit type={'danger'}
                                        icon={'fa fa-trash'} />
                                ) : (
                                    <Button
                                        text='Salvar'
                                        submit
                                        type={'success'}
                                        icon={'fa fa-check'} />
                                )}
                            </Grid>
                            <Grid cols='6 6 4 3'>
                                <Button
                                    text='Voltar'
                                    type={'warning'}
                                    icon={'fa fa-chevron-left'}
                                    event={() => this.props.setModoTela('lista')} />
                            </Grid>
                        </Row>
                    </ContentCardFooter>
                </Form>
            </ContentCard>
        )
    }


}

CompeticaoInscricaoForm = reduxForm({ form: 'competicaoInscricaoForm', destroyOnUnmount: false })(CompeticaoInscricaoForm);
const mapStateToProps = state => ({
    sessao: state.auth.usuario,
    formularioValues: getFormValues('competicaoInscricaoForm')(state),
    registro: state.competicaoInscricao.registro,
    listaCompeticaoCategoria: state.competicaoInscricao.listaCompeticaoCategoria,
    listaCompeticao: state.competicaoInscricao.listaCompeticao,
    listaCompeticaoImpedimento: state.competicaoInscricao.listaCompeticaoImpedimento,


});
const mapDispatchToProps = dispatch => bindActionCreators({
    setModoTela, initForm, getListaCompeticaoCategoria
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CompeticaoInscricaoForm);
