import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../auth/authActions';

class NavbarJogador extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false };
	}
	changeOpen() {
		this.setState({ open: !this.state.open });
	}
	render() {
		const { nome, email } = this.props.usuario;
		return (
			<ul className="navbar-nav ml-auto">
				<li className="nav-item dropdown">
					{/*<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-bell"></i>
						<span className="badge badge-warning navbar-badge">15</span>
					</a>*/}
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<span className="dropdown-item dropdown-header">15 Notifications</span>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item">
							<i className="fas fa-envelope mr-2"></i> 4 new messages
							<span className="float-right text-muted text-sm">3 mins</span>
						</a>
					<div className="dropdown-divider"></div>
					<a href="#" className="dropdown-item">
						<i className="fas fa-users mr-2"></i> 8 friend requests
						<span className="float-right text-muted text-sm">12 hours</span>
					</a>
					<div className="dropdown-divider"></div>
					<a href="#" className="dropdown-item">
						<i className="fas fa-file mr-2"></i> 3 new reports
						<span className="float-right text-muted text-sm">2 days</span>
					</a>
					<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
					</div>
				</li>
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#" style={{ color: '#fff' }}>
						<i className="far fa-user"></i>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<span className="dropdown-item dropdown-header">Perfil</span>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item">
							<i className="fas fa-user mr-2"></i> Nome
							<span className="float-right text-muted text-sm">{nome}</span>
						</a>
					<div className="dropdown-divider"></div>
					<a href="#" className="dropdown-item">
						<i className="fas fa-envelope mr-2"></i> Usuário
						<span className="float-right text-muted text-sm">{email}</span>
					</a>
					<div className="dropdown-divider"></div>
						<a href="#" onClick={this.props.logout}
							className="btn btn-block btn-primary btn-sm"
							style={{ backgroundColor: '#B1F005', color: '#0D1B2A', fontWeight: 'bold' }}>Sair</a>
					</div>
				</li>
			</ul>
		);
	}
}

const mapStateToProps = state => ({usuario: state.auth.usuario});
const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NavbarJogador);
