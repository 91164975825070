import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm
} from './quadraActions';

class QuadraForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		fileSelector.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;

			if (file) {

				if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas imagens (.PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					this.props.initForm({
						...this.props.formularioValues,
						imagem_capa_base64: reader.result
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelector = fileSelector;

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='id_esporte'
								component={Select}
								options={this.props.listaEsporte}
								label='Esporte'
								cols='12 12 12 12'
								placeholder='Selecione o esporte'
								readOnly={readOnly} />

							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='descricao'
								component={LabelAndInput}
								label='Descrição' placeholder='Informe a descrição'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='endereco'
								component={LabelAndInput}
								label='Endereço' placeholder='Informe o endereço'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='link_localizacao'
								component={LabelAndInput}
								label='Link da Localização' placeholder='Informe a localização'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='quantidade_minima_jogo'
								component={Select}
								options={[{ id: 1, valor: '1 horário' }, { id: 2, valor: '2 horários' }, { id: 3, valor: '3 horários' }, { id: 4, valor: '4 horários' }]}
								label='Quantidade Mínima para Jogo'
								cols='12 12 12 12'
								placeholder='Selecione o horário mínimo'
								readOnly={readOnly} />

							<Field
								name='quantidade_minima_aula'
								component={Select}
								options={[{ id: 1, valor: '1 horário' }, { id: 2, valor: '2 horários' }, { id: 3, valor: '3 horários' }, { id: 4, valor: '4 horários' }]}
								label='Quantidade Mínima para Aula'
								cols='12 12 12 12'
								placeholder='Selecione o horário mínimo'
								readOnly={readOnly} />

							<Field
								name='horario_unitario'
								component={Select}
								options={[{ id: 30, valor: '30 minutos' }, { id: 60, valor: '1 hora' }]}
								label='Unidade de Horário'
								cols='12 12 12 12'
								placeholder='Selecione o horário unitário'
								readOnly={readOnly} />

							<Field
								name='preco_unitario'
								component={LabelAndInputNumber}
								label='Preço Unitário' placeholder='Informe o Preço Unitário'
								cols='12 6 4 3'
								readOnly={readOnly}
								casas={2} />

						</Row>
						<Row>

							<Grid cols='12 12 12 12'>
								<label>Jogo de Segunda a Sexta-Feira</label>
							</Grid>
							<Field
								name='horario_inicio'
								component={Select}
								options={this.props.listaHoras}
								label='De'
								cols='6 6 4 3'
								placeholder='Selecione o horário'
								readOnly={readOnly} />

							<Field
								name='horario_fim'
								component={Select}
								options={this.props.listaHoras}
								label='Até'
								cols='6 6 4 3'
								placeholder='Selecione o horário'
								readOnly={readOnly} />

						</Row>
						<Row>

							<Grid cols='12 12 12 12'>
								<label>Aula de Segunda a Sexta-Feira</label>
							</Grid>
							<Field
								name='horario_inicio_aula'
								component={Select}
								options={this.props.listaHoras}
								label='De'
								cols='6 6 4 3'
								placeholder='Selecione o horário'
								readOnly={readOnly} />

							<Field
								name='horario_fim_aula'
								component={Select}
								options={this.props.listaHoras}
								label='Até'
								cols='6 6 4 3'
								placeholder='Selecione o horário'
								readOnly={readOnly} />

						</Row>
						<Row>

							<Grid cols='12 12 12 12'>
								<label>Sábado</label>
							</Grid>
							<Field
								name='horario_sabado_inicio'
								component={Select}
								options={this.props.listaHoras}
								label='De'
								cols='6 6 4 3'
								placeholder='Selecione o horário'
								readOnly={readOnly} />

							<Field
								name='horario_sabado_fim'
								component={Select}
								options={this.props.listaHoras}
								label='Até'
								cols='6 6 4 3'
								placeholder='Selecione o horário'
								readOnly={readOnly} />

						</Row>
						<Row>

							<Grid cols='12 12 12 12'>
								<label>Domingo</label>
							</Grid>
							<Field
								name='horario_domingo_inicio'
								component={Select}
								options={this.props.listaHoras}
								label='De'
								cols='6 6 4 3'
								placeholder='Selecione o horário'
								readOnly={readOnly} />

							<Field
								name='horario_domingo_fim'
								component={Select}
								options={this.props.listaHoras}
								label='Até'
								cols='6 6 4 3'
								placeholder='Selecione o horário'
								readOnly={readOnly} />

						</Row>
						{/*
						<Row>
							<Field
								name='tipo_domingo'
								component={Select}
								options={this.props.listaTipo}
								label='Domingo'
								cols='12 6 4 3'
								placeholder='Selecione o que é permitido'
								readOnly={readOnly} />

							<Field
								name='tipo_segunda'
								component={Select}
								options={this.props.listaTipo}
								label='Segunda-feira'
								cols='12 6 4 3'
								placeholder='Selecione o que é permitido'
								readOnly={readOnly} />

							<Field
								name='tipo_terca'
								component={Select}
								options={this.props.listaTipo}
								label='Terça-feira'
								cols='12 6 4 3'
								placeholder='Selecione o que é permitido'
								readOnly={readOnly} />

							<Field
								name='tipo_quarta'
								component={Select}
								options={this.props.listaTipo}
								label='Quarta-feira'
								cols='12 6 4 3'
								placeholder='Selecione o que é permitido'
								readOnly={readOnly} />

							<Field
								name='tipo_quinta'
								component={Select}
								options={this.props.listaTipo}
								label='Quinta-feira'
								cols='12 6 4 3'
								placeholder='Selecione o que é permitido'
								readOnly={readOnly} />

							<Field
								name='tipo_sexta'
								component={Select}
								options={this.props.listaTipo}
								label='Sexta-feira'
								cols='12 6 4 3'
								placeholder='Selecione o que é permitido'
								readOnly={readOnly} />

							<Field
								name='tipo_sabado'
								component={Select}
								options={this.props.listaTipo}
								label='Sábado'
								cols='12 6 4 3'
								placeholder='Selecione o que é permitido'
								readOnly={readOnly} />
						</Row>*/}

						<Row>
							{/*}<Grid cols='12 12 12 12'>
								<label>Capa</label>
							</Grid>
							<Grid cols='12 6 4 3'>
								<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelector.click()}>
									<img src={this.props.formularioValues.imagem_capa_base64 ? this.props.formularioValues.imagem_capa_base64 : (this.props.formularioValues.imagem_capa ? `https://api-golden.infinitum-ti.com/${this.props.formularioValues.imagem_capa}` : imagemAdd)} style={{ height: 200 }} />
								</span>
							</Grid>*/}
							<Field
								name='bloqueado'
								component={LabelAndCheckbox}
								label='Bloqueada' placeholder='Informe se está bloqueado'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

QuadraForm = reduxForm({form: 'quadraForm', destroyOnUnmount: false})(QuadraForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('quadraForm')(state),
	registro: state.quadra.registro,
	listaEsporte: state.quadra.listaEsporte,
	listaHoras: state.quadra.listaHoras,
	listaTipo: state.quadra.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(QuadraForm);
