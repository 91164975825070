const INITIAL_STATE = { lista: [] };

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
		case 'DASHBOARD_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };

        default:
            return state;
    }
}
