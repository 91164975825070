import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import DashboardJogador from '../dashboardJogador/dashboardJogador';
import DashboardJogadorHorarios from '../dashboardJogador/dashboardJogadorHorarios';
import Usuario from '../usuario/usuario';
import Agenda from '../agenda/agenda';
import Classificacao from '../classificacao/classificacao';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={DashboardJogador} />
			<Route path='/horarios' component={DashboardJogadorHorarios} />
			<Route path='/classificacao' component={Classificacao} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
