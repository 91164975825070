import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'QUADRA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				preco_unitario: FormatUtils.formatarValorTela(registro.preco_unitario, 2)
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('quadraForm', {
			...registro,
			preco_unitario: FormatUtils.formatarValorTela(registro.preco_unitario, 2)
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'QUADRA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/quadra`);
    return {
        type: 'QUADRA_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/quadra`, {
				...registro,
				preco_unitario: FormatUtils.formatarValorBanco(registro.preco_unitario, 2),
				bloqueado: registro.bloqueado || false,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/quadra`, {
				...registro,
				preco_unitario: FormatUtils.formatarValorBanco(registro.preco_unitario, 2),
				bloqueado: registro.bloqueado || false,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/quadra?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEsporte() {
    const request = axios.get(`${consts.API_URL}/esporte/listarSelect`);
    return {
        type: 'QUADRA_ESPORTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaHoras() {

	let data = new Date();
	data.setHours(0);
	data.setMinutes(0);

	let lista = [];

	for (var i = 0; i < 48; i++) {
		data.setMinutes(data.getMinutes() + 30);
		lista.push({
			id: `${data.getHours() < 10 ? '0' : ''}${data.getHours()}:${data.getMinutes() < 10 ? '0' : ''}${data.getMinutes()}`,
			valor: `${data.getHours() < 10 ? '0' : ''}${data.getHours()}:${data.getMinutes() < 10 ? '0' : ''}${data.getMinutes()}`
		});
	}

    return {
        type: 'QUADRA_HORAS_SELECT_LISTADO',
        payload: lista
    };
}
