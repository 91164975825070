import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'COMANDA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('comandaForm', {
			...registro
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'COMANDA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/comanda`);
    return {
        type: 'COMANDA_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/comanda`, {
				...registro,
				telefone: FormatUtils.removerMascara(registro.telefone),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(initForm({
					...registro,
					id: resp.data.id
				}));
				dispatch(getListaItens(resp.data.id));
				dispatch(getListaPagamentos(resp.data.id));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/comanda`, {
				...registro,
				telefone: FormatUtils.removerMascara(registro.telefone),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/comanda?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUsuario() {

	let lista = [];
	return async dispatch => {
		await axios.get(`${consts.API_URL}/usuario/listarSelect?jogador=true`)
		.then(resp => {
			lista = resp.data;
		}).catch(e => {
			setErroAPI(e);
		});

		await axios.get(`${consts.API_URL}/usuario/listarSelect?usuario=true`)
		.then(resp => {
			for (var i = 0; i < resp.data.length; i++) {
				lista.push(resp.data[i]);
			}
		}).catch(e => {
			setErroAPI(e);
		});

		dispatch({
	        type: 'COMANDA_USUARIO_SELECT_LISTADO',
	        payload: {
				data: lista
			}
	    });
	};
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'COMANDA_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

//ITEM

export function setModoTelaItem(modo, registro = {}) {
    return {
        type: 'COMANDA_ITEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}
export function initFormItem(registro = {}) {
    return [
        initialize('comandaItemForm', {
			...registro
		})
    ];
}

export function getListaItens(idComanda) {	
    const request = axios.get(`${consts.API_URL}/comandaItem?id_comanda=${idComanda || 0}`);
    return {
        type: 'COMANDA_ITEM_LISTADO',
        payload: request
    };
}

export function salvarItem(registro) {
    return (dispatch, getState) => {

		let quantidade = parseFloat(FormatUtils.formatarValorBanco(registro.quantidade, 0));
		let produto = getState().comanda.listaProduto.filter(item => item.id == registro.id_produto)[0];
		let precoUnitario = parseFloat(produto ? produto.preco_unitario || 0 : 0);
		
		if (!registro.id) {

	        axios.post(`${consts.API_URL}/comandaItem`, {
				...registro,
				quantidade: registro.quantidade,
				preco_unitario: precoUnitario,
				valor: quantidade * precoUnitario,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTelaItem('lista'));
				dispatch(getListaItens(registro.id_comanda));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/comandaItem`, {
				...registro,
				quantidade: registro.quantidade,
				preco_unitario: precoUnitario,
				valor: quantidade * precoUnitario,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTelaItem('lista'));
				dispatch(getListaItens(registro.id_comanda));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirItem(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/comandaItem?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaItens(registro.id_comanda));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Pagamentos

export function setModoTelaPagamento(modo, registro = {}) {
    return {
        type: 'COMANDA_PAGAMENTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}
export function initFormPagamento(registro = {}) {
    return [
        initialize('comandaPagamentoForm', {
			...registro
		})
    ];
}

export function getListaPagamentos(idComanda) {
    const request = axios.get(`${consts.API_URL}/comandaPagamento?id_comanda=${idComanda || 0}`);
    return {
        type: 'COMANDA_PAGAMENTO_LISTADO',
        payload: request
    };
}

export function salvarPagamento(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {

	        axios.post(`${consts.API_URL}/comandaPagamento`, {
				...registro,
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTelaPagamento('lista'));
				dispatch(getListaPagamentos(registro.id_comanda));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/comandaPagamento`, {
				...registro,
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTelaPagamento('lista'));
				dispatch(getListaPagamentos(registro.id_comanda));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirPagamento(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/comandaPagamento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaPagamentos(registro.id_comanda));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
