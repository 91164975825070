import React, { Component } from 'react';
import MenuItem from './menuItemJogador';
import MenuTree from './menuTree';
import { withRouter } from 'react-router-dom';

class MenuJogador extends Component {

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		return (
			<nav className="mt-2">
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
			        <MenuItem path='/' label='Quadras' icon='fas fa-table-tennis' active={selecionado == '/'} />
					<MenuItem path='/horarios' label='Horários' icon='fas fa-calendar-alt' active={selecionado == '/horarios'} />
					<MenuItem path='classificacao' label='Ranking' icon='fas fa-trophy' active={selecionado == '/classificacao'} />
		    	</ul>
			</nav>
		);

	}

}

export default withRouter(MenuJogador);
