import * as moment from 'moment';
import 'moment/locale/pt-br';

export function getDataAtual() {

	let data = new Date();
	if (moment(new Date()).format('Z') == '-02:00') {
		data.setHours(data.getHours() - 1);
	}

	return moment(data).format('YYYY/MM/DD HH:mm:ss');

}

export function getData(data) {

	data = new Date(data);
	if (moment(new Date()).format('Z') == '-03:00') {
		data.setHours(data.getHours() + 1);
	}

	return data;
}

export function getDataFormatada(data, formato = 'YYYY/MM/DD HH:mm:ss') {

	data = new Date(data);
	if (formato.length == 10) {
		data.setHours(data.getHours() + 3);
	} else {
		if (moment(new Date()).format('Z') == '-03:00') {
			data.setHours(data.getHours() + 1);
		}
	}

	return moment(data).format(formato);
}

export function getDataHoraFormatada(data, formato = 'YYYY/MM/DD HH:mm:ss') {

	data = new Date(data);
	if (formato.length == 10) {
		data.setHours(data.getHours() + 3);
	} else {
		if (moment(new Date()).format('Z') == '-03:00') {
			data.setHours(data.getHours() + 1);
		}
	}

	return moment(data).format(formato);
}

export function getDataBanco(data) {

	if (data) {
		return `${data.split('/')[2]}/${data.split('/')[1]}/${data.split('/')[0]}`;
	} else {
		return null;
	}
}

export function getDataHoraBanco(dataHora) {

	if (dataHora) {
		let data = `${dataHora.split(' ')[0].split('/')[2]}/${dataHora.split(' ')[0].split('/')[1]}/${dataHora.split(' ')[0].split('/')[0]}`;
		let hora = dataHora.split(' ')[1];

		return `${data} ${hora}`;
	} else {
		return null;
	}
}
