const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
    listaCompeticao: [],
    

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMPETICAO_IMPEDIMENTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'COMPETICAO_IMPEDIMENTO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'COMPETICAO_IMPEDIMENTO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};
        
            
         case 'COMPETICAO_CATEGORIA_COMPETICAO_SELECT_LISTADO':
            return {
                    ...state,
                 listaCompeticao: action.payload.data
             };

        default:
            return state;
    }
}
