import React from 'react';
import MenuJogador from './menuJogador';
import imagemLogo from '../../assets/images/sologoamarelo.png';
import imagemNome from '../../assets/images/sonomeamarelo.png';

export default props => (
	<aside className="main-sidebar elevation-4 sidebar-light-indigo" style={{ backgroundColor: '#0D1B2A' }}>
		<a href="../../" className="brand-link navbar-light" style={{ backgroundColor: '#0D1B2A' }}>
			<img src={imagemLogo} alt="Golden Logo" className="brand-image" />
			<img className="brand-text font-weight-light" style={{ height: 30, paddingTop: 0 }} src={imagemNome} />
		</a>
		<div className="sidebar">
			<MenuJogador />
		</div>
	</aside>
)
