import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import CompeticaoCategoria from './competicaoCategoria/competicaoCategoria';
import CompeticaoImpedimento from './competicaoImpedimento/competicaoImpedimento'


import {
    setModoTela, initForm
} from './competicaoActions';

class CompeticaoForm extends Component {

    state = {

    }

    componentDidMount() {

        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.addEventListener("change", (data) => {

            let reader = new FileReader();
            let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;

            if (file) {

                if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
                    setError('Selecione apenas imagens (.PNG, .JPG e .JPEG)');
                    return;
                }

                reader.onloadend = () => {

                    this.props.initForm({
                        ...this.props.formularioValues,
                        imagem_icone_base64: reader.result
                    });

                }

                reader.readAsDataURL(file);

            }
        });

        this.fileSelector = fileSelector;

    }


    componentWillMount() {
        
    }

    render() {
        
        let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
            <>
                <ContentCard>
                <ContentCardHeader>
				<label>Competição</label>
				</ContentCardHeader>
                    <Form event={this.props.handleSubmit}>
                        <ContentCardBody>
                            <Row>
                                <Field
                                    name='nome'
                                    component={LabelAndInput}
                                    label='Nome' placeholder='Informe o nome'
                                    cols='12 12 12 12'
                                    readOnly={readOnly} />


                                <Field
                                    name='descricao'
                                    component={LabelAndInput}
                                    label='Descrição' placeholder='Informe a descrição'
                                    cols='12 12 12 12'
                                    readOnly={readOnly} />
                                
                                




                            </Row>
                        </ContentCardBody>
                        <ContentCardFooter>
                            <Row alignCenter>
                                <Grid cols='6 6 4 3'>
                                    {this.props.excluir ? (
                                        <Button
                                            text='Excluir'
                                            submit type={'danger'}
                                            icon={'fa fa-trash'} />
                                    ) : (
                                        <Button
                                            text='Salvar'
                                            submit
                                            type={'success'}
                                            icon={'fa fa-check'} />
                                    )}
                                </Grid>
                                <Grid cols='6 6 4 3'>
                                    <Button
                                        text='Voltar'
                                        type={'warning'}
                                        icon={'fa fa-chevron-left'}
                                        event={() => this.props.setModoTela('lista')} />
                                </Grid>
                            </Row>
                        </ContentCardFooter>
                    </Form>
                </ContentCard>
            
             {this.props.formularioValues.id ? <CompeticaoCategoria/> : null}
             {this.props.formularioValues.id ? <CompeticaoImpedimento/> : null}
             

            
            </>
        )
    }


}

CompeticaoForm = reduxForm({ form: 'competicaoForm', destroyOnUnmount: false })(CompeticaoForm);
const mapStateToProps = state => ({
    sessao: state.auth.usuario,
    formularioValues: getFormValues('competicaoForm')(state),
    registro: state.competicao.registro,

});
const mapDispatchToProps = dispatch => bindActionCreators({
    setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CompeticaoForm);
