import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela } from './usuarioActions';

class UsuarioForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir || this.props.senha ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='email'
								component={LabelAndInput}
								label='E-mail *'
								placeholder='Informe o E-mail'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='telefone'
								component={LabelAndInputMask}
								label='Telefone *'
								placeholder='Informe o Telefone'
								cols='12 12 12 12'
								mask='(99) 9 9999-9999'
								readOnly={readOnly} />

							<Field
								name='cpf'
								component={LabelAndInputMask}
								label='CPF *'
								placeholder='Informe o CPF'
								cols='12 12 12 12'
								mask='999.999.999-99'
								readOnly={readOnly} />

							{!this.props.formularioValues.id || this.props.senha ? (
								<>
									<Field
										name='senha'
										component={LabelAndInput}
										type="password"
										label='Senha *'
										placeholder='Informe a Senha'
										cols='12 12 12 12' />

									<Field
										name='senha_confirmacao'
										component={LabelAndInput}
										type="password"
										label='Confirme a Senha *'
										placeholder='Confirme a Senha'
										cols='12 12 12 12' />
								</>
							) : null}

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.usuario.registro,
	formularioValues: getFormValues('usuarioForm')(state),
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
