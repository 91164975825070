import React from 'react'
import { Link } from 'react-router-dom'

export default props => (
    <li className="nav-item">
        <Link to={props.path} className={`nav-link ${props.active ? 'active' : ''}`} style={{ backgroundColor: props.active ? '#354153' : null }}>
            <i className={`nav-icon ${props.icon}`} style={{ color: '#fff' }}></i>
			<p style={{ color: '#fff' }}>
				{props.label}
				<span class={`badge badge-${props.spanType} right`}>{props.span}</span>
			</p>
        </Link>
    </li>
)
