import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import Header from '../common/template/header';
import HeaderJogador from '../common/template/headerJogador';

import SideBar from '../common/template/sideBar';
import SideBarJogador from '../common/template/sideBarJogador';

import Footer from '../common/template/footer';
import Messages from '../common/msg/messages';

import Routes from './routes';
import RoutesJogador from './routesJogador';

import { buscarSessao } from '../auth/authActions';

class App extends Component {

	componentWillMount() {
		this.props.buscarSessao();
	}

	render() {

		if (this.props.sessao && this.props.sessao.acesso_administrador) {
			return (
				<HashRouter>
					<div className='wrapper'>
						<Header />
						<SideBar />
						<Routes />
						<Messages />
					</div>
				</HashRouter>
			);
		} else if (this.props.sessao && this.props.sessao.acesso_jogador) {
			return (
				<HashRouter>
					<div className='wrapper'>
						<HeaderJogador />
						<SideBarJogador />
						<RoutesJogador />
						<Messages />
					</div>
				</HashRouter>
			);
		} else {
			return <div />
		}
	}
}


const mapStateToProps = state => ({
	sessao: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({
	buscarSessao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(App);
