import './auth.css';
import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModoTela, login, signup, esqueciSenha, initForm } from './authActions';
import Row from '../common/layout/row';
import If from '../common/operator/if';
import Messages from '../common/msg/messages';
import Input from '../common/form/inputAuth';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { ThemeProvider } from '@material-ui/styles';
import imagemLogo from '../assets/images/logo.png';

class Auth extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onSubmit(values) {
		const { login, signup, esqueciSenha } = this.props;

		this.props.modoTela == 'login' ? login(values) : (this.props.modoTela == 'cadastro' ? signup(values) : (this.props.modoTela == 'esqueciSenha' ? esqueciSenha({ ...values, codigo_gerado: `${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}` }) : login({ ...values, alterar_senha: true })));
	}

	getStyles() {
		return makeStyles((theme) => ({
			paper: {
				marginTop: theme.spacing(8),
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center'
			},
			avatar: {
				margin: theme.spacing(1),
				backgroundColor: theme.palette.secondary.main
			},
			form: {
				width: '100%',
				marginTop: theme.spacing(1),
			},
			submit: {
				margin: theme.spacing(3, 0, 2),
			},
		}));
	}

	getTheme() {

		return createMuiTheme({
			palette: {
				primary: {
					// light: será calculada com base em palette.primary.main,
					main: '#B1F005',
					// dark: será calculada com base em palette.primary.main,
					contrastText: '#0D1B2A'
				},
				secondary: {
					// light: '#0066ff',
					main: '#B1F005',
					// dark: será calculada com base palette.secondary.main,
					// contrastText: '#ffcc00',
				},
				// Usado por `getContrastText()` para maximizar o contraste entre
				// o plano de fundo e o texto.
				contrastThreshold: 3,
				// Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
				// dois índices dentro de sua paleta tonal.
				// Por exemplo, mude de Red 500 para Red 300 ou Red 700.
				tonalOffset: 0.2,
			},
		});
	}

	render() {

		const classes = this.getStyles();

		const { loginMode } = this.state;
		const { handleSubmit } = this.props;

		return (
			<ThemeProvider theme={this.getTheme()}>
				<div style={{ backgroundColor: '#0D1B2A', height: '100%' }}>
					<div style={{ display: 'flex', alignItems: 'center', height: '100vh', backgroundSize: 'cover' }}>
						<Container component="main" maxWidth="xs">
							<CssBaseline />
							{this.props.modoTela == 'login' ? (
								<div className={classes.paper} style={{ textAlign: 'center' }}>
									<img src={imagemLogo} style={{ width: 200 }}/>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.onSubmit(v))}>
										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='email'
											label='E-mail'
											name='email'
											autoFocus
											focused
											style={{ marginTop: 0, marginBottom: 0 }}
											inputProps={{
												style: { color: '#fff', border: '#fff'}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													email: e.target.value
												});
											})} />

										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='senha'
											label='Senha'
											name='senha'
											type='password'
											focused
											style={{ marginTop: 10, marginBottom: 0 }}
											inputProps={{
												style: { color: '#fff', border: '#fff'}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha: e.target.value
												});
											})} />

										<div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 16 }}>
											<a href='#' style={{ color: '#fff' }} onClick={() => this.props.setModoTela('esqueciSenha')}>Esqueci minha senha</a>
										</div>

										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit}
											style={{ marginTop: 16 }}>
											Entrar
										</Button>
										<Button
											fullWidth
											variant="outlined"
											color="primary"
											className={classes.submit}
											style={{ marginTop: 16 }}
											onClick={() => this.props.setModoTela('cadastro')}>
											Criar Conta
										</Button>
									</form>
								</div>
							) : (this.props.modoTela == 'cadastro' ? (
								<div className={classes.paper} style={{ textAlign: 'center' }}>
									<img src={imagemLogo} style={{ width: 100 }}/>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.onSubmit(v))}>
										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='nome'
											label='Nome'
											name='nome'
											autoFocus
											focused
											style={{ marginTop: 0, marginBottom: 0 }}
											inputProps={{
												style: { color: '#fff', border: '#fff', padding: 12}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													nome: e.target.value
												});
											})} />

										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='email'
											label='E-mail'
											name='email'
											focused
											inputProps={{
												style: { color: '#fff', border: '#fff', padding: 12}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													email: e.target.value
												});
											})} />

										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='cpf'
											label='CPF'
											name='cpf'
											type='numeric'
											focused
											style={{ marginTop: 10, marginBottom: 0 }}
											inputProps={{
												style: { color: '#fff', border: '#fff', padding: 12}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													cpf: e.target.value
												});
											})} />

										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='telefone'
											label='Telefone'
											name='telefone'
											type='numeric'
											focused
											style={{ marginTop: 10, marginBottom: 0 }}
											inputProps={{
												style: { color: '#fff', border: '#fff', padding: 12}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													telefone: e.target.value
												});
											})} />

										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='senha'
											label='Senha'
											name='senha'
											type='password'
											focused
											style={{ marginTop: 10, marginBottom: 0 }}
											inputProps={{
												style: { color: '#fff', border: '#fff', padding: 12}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha: e.target.value
												});
											})} />

										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='senha_confirmacao'
											label='Confirme sua Senha'
											name='senha_confirmacao'
											type='password'
											focused
											style={{ marginTop: 10, marginBottom: 0 }}
											inputProps={{
												style: { color: '#fff', border: '#fff', padding: 12}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha_confirmacao: e.target.value
												});
											})} />

										<Checkbox
											color="primary"
											style={{ color: '#fff', border: '#fff', padding: 12 }}
											inputProps={{
												style: { color: '#fff', border: '#fff', padding: 12}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(event) => {
												this.props.initForm({
													...this.props.formularioValues,
													termo_uso: this.props.formularioValues && this.props.formularioValues.termo_uso ? false : true
												});
											}}/>
											<Typography variant="h7" style={{ color: '#fff', border: '#fff', margin: 0, padding: 0 }}>Li e Concordo com os Termos de Uso e a Política de Privacidade</Typography>

										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit}
											style={{ marginTop: 16 }}>
											Criar Conta
										</Button>
									</form>
								</div>
							) : (
								<div className={classes.paper} style={{ textAlign: 'center' }}>
									<img src={imagemLogo} style={{ width: 200 }}/>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.onSubmit(v))}>
										<TextField
											variant='outlined'
											margin='normal'
											required
											fullWidth
											color='primary'
											id='email'
											label='E-mail'
											name='email'
											focused
											inputProps={{
												style: { color: '#fff', border: '#fff', padding: 12}
											}}
											InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													email: e.target.value
												});
											})} />

										{this.props.modoTela == 'alterarSenha' ? (
											<div>
												<TextField
													variant='outlined'
													margin='normal'
													required
													fullWidth
													color='primary'
													id='codigo_validacao'
													label='Código recebido no email'
													name='codigo_validacao'
													type='numeric'
													focused
													style={{ marginTop: 10, marginBottom: 0 }}
													inputProps={{
														style: { color: '#fff', border: '#fff', padding: 12}
													}}
													InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
													onChange={(e => {
														this.props.initForm({
															...this.props.formularioValues,
															codigo_validacao: e.target.value
														});
													})} />

												<TextField
													variant='outlined'
													margin='normal'
													required
													fullWidth
													color='primary'
													id='senha'
													label='Senha'
													name='senha'
													type='password'
													focused
													style={{ marginTop: 10, marginBottom: 0 }}
													inputProps={{
														style: { color: '#fff', border: '#fff', padding: 12}
													}}
													InputLabelProps={{ style: { color: '#fff', backgroundColor: '#0D1B2A' }}}
													onChange={(e => {
														this.props.initForm({
															...this.props.formularioValues,
															senha: e.target.value
														});
													})} />
											</div>
										) : null}

										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit}
											style={{ marginTop: 16 }}>
											{this.props.modoTela == 'alterarSenha' ? 'Confirmar' : 'Enviar E-mail'}
										</Button>
									</form>
								</div>
							))}
							<Snackbar
								open={this.props.message.open}>
								<MuiAlert severity={this.props.message.type} variant="filled">
			    					{this.props.message.text}
			  					</MuiAlert>
						 	</Snackbar>
						</Container>
					</div>
				</div>
			</ThemeProvider>
		);
	}
}

Auth = reduxForm({form: 'authForm'})(Auth);
const mapStateToProps = state => ({
	modoTela: state.auth.modoTela,
	message: state.auth.message,
	formularioValues: getFormValues('authForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, login, signup, esqueciSenha, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
