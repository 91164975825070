const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaItens: [],
	listaPagamentos: [],
	listaUsuario: [],
	listaProduto: [],
	modoTelaItem: 'lista',
	modoTelaPagamento: 'lista'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMANDA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
				listaItens: action.payload.modo == 'lista' ? [] : state.listaItens,
				listaPagamentos: action.payload.modo == 'lista' ? [] : state.listaPagamentos,
			};

        case 'COMANDA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'COMANDA_ITEM_LISTADO':
            return {
				...state,
				listaItens: action.payload.data
			};

		case 'COMANDA_PAGAMENTO_LISTADO':
            return {
				...state,
				listaPagamentos: action.payload.data
			};

		case 'COMANDA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'COMANDA_USUARIO_SELECT_LISTADO':
            return { ...state,
				listaUsuario: action.payload.data
			};
			
		case 'COMANDA_PRODUTO_SELECT_LISTADO':
			return { ...state,
				listaProduto: action.payload.data
			};
		
		case 'COMANDA_ITEM_MODO_TELA':
			return { ...state,
				modoTelaItem: action.payload.modo
			};

		case 'COMANDA_PAGAMENTO_MODO_TELA':
			return { ...state,
				modoTelaPagamento: action.payload.modo
			};
		
        default:
            return state;
    }
}
