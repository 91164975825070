import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'DASHBOARD_JOGADOR_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				preco_unitario: FormatUtils.formatarValorTela(registro.preco_unitario, 2)
			}
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_JOGADOR_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('agendaJogadorForm', {
			...registro,
			preco_unitario: FormatUtils.formatarValorTela(registro.preco_unitario, 2)
		})
    ];
}

export function getLista(id_usuario_jogador) {
    const request = axios.get(`${consts.API_URL}/agenda?id_usuario_jogador=${id_usuario_jogador}`);
    return {
        type: 'DASHBOARD_JOGADOR_LISTADO',
        payload: request
    };
}

export function isUsuarioAtivo(id) {
    const request = axios.get(`${consts.API_URL}/usuario?id=${id}&jogador=true`);
    return {
        type: 'DASHBOARD_JOGADOR_ATIVO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return async (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/agenda`, {
				...registro,
				preco_unitario: FormatUtils.formatarValorBanco(registro.preco_unitario, 2),
				bloqueado: registro.bloqueado || false,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
				visto: false
			}).then(resp => {
				setSuccess('Horário agendado com sucesso.');
				dispatch(getLista(getState().auth.usuario.id));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/agenda`, {
				...registro,
				preco_unitario: FormatUtils.formatarValorBanco(registro.preco_unitario, 2),
				bloqueado: registro.bloqueado || false,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/agenda?id=${registro.id}&todos=${registro.todos ? true : false}&visto=false&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Horário cancelado com sucesso.');
			dispatch(getLista(registro.id_usuario_jogador));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaHorariosAgendados(data) {

	return dispatch => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/agenda/horarios?data_agenda=${data}`).then(resp => {
			dispatch({
				type: 'DASHBOARD_JOGADOR_AGENDADO_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(e => {
			dispatch(setAguardando(false));
			setErroAPI(e);
		});
	};
}

export function getListaEsporte() {
    const request = axios.get(`${consts.API_URL}/esporte/listarSelect`);
    return {
        type: 'DASHBOARD_JOGADOR_ESPORTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProfessor() {
    const request = axios.get(`${consts.API_URL}/professor/listarSelect`);
    return {
        type: 'DASHBOARD_JOGADOR_PROFESSOR_SELECT_LISTADO',
        payload: request
    };
}

export function getListaQuadra() {
    const request = axios.get(`${consts.API_URL}/quadra`);
    return {
        type: 'DASHBOARD_JOGADOR_QUADRA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaHoras() {

	let data = new Date();
	data.setHours(0);
	data.setMinutes(0);

	let lista = [];

	for (var i = 0; i < 48; i++) {
		data.setMinutes(data.getMinutes() + 30);
		lista.push({
			id: `${data.getHours() < 10 ? '0' : ''}${data.getHours()}:${data.getMinutes() < 10 ? '0' : ''}${data.getMinutes()}`,
			valor: `${data.getHours() < 10 ? '0' : ''}${data.getHours()}:${data.getMinutes() < 10 ? '0' : ''}${data.getMinutes()}`
		});
	}

    return {
        type: 'DASHBOARD_JOGADOR_HORAS_SELECT_LISTADO',
        payload: lista
    };
}
