const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaCompeticao: [],
    listaCategoria: [],

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMPETICAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'COMPETICAO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'COMPETICAO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};
		case 'COMPETICAO_CATEGORIA_CATEGORIA_SELECT_LISTADO':
			return {
					  ...state,
				 listaCategoria: action.payload.data
			 };
				
		case 'COMPETICAO_CATEGORIA_COMPETICAO_SELECT_LISTADO':
			return {
				...state,
			 listaCompeticao: action.payload.data
			 };

        default:
            return state;
    }
}
