import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';

import imagemBanner from '../assets/images/banner.png';
import adesivoBig from '../assets/images/adesivo_big.png';
import adesivoSchirmann from '../assets/images/adesivo_schirmann.png';
import adesivoNorthin from '../assets/images/adesivo_northin.png';
import adesivoCoi from '../assets/images/adesivo_coi.png';

class Classificacao extends Component {

	state = {
		lista: [{
		    categoria: '4ª FEMININA',
		   classificacao: [
		    { posicao: 1, jogador: 'Maria Fernanda Batista', etapa1: 100, etapa2 : 100, total: 200 },
		                { posicao: 2, jogador: 'Marília Damian', etapa1: 100,  etapa2 : 60,  total: 160 },
		    { posicao: 3,  jogador: 'Mariana Missel cesar ', etapa1: 40,  etapa2 : 100,  total: 140 },
		                { posicao: 4,  jogador: 'Karla Schwertz ', etapa1: 60,  etapa2 : 60,  total: 120 },
		                { posicao: 5,  jogador: 'Luciana Ely Chechi ', etapa1: 40,  etapa2 : 70,  total: 110 },
		    { posicao: 5,  jogador: 'Renata Patias ', etapa1: 40,  etapa2 : 70,  total: 110 },
		                { posicao: 6,  jogador: 'Camila Dal Pai ', etapa1: 60,  etapa2 : 40,  total: 100 },
		    { posicao: 7,  jogador: 'Carlise Borré ', etapa1: 70,  etapa2 : 10,  total: 80 },
		                { posicao: 8,  jogador: 'Sabrina Hernandez Kliemann ', etapa1: 60,  etapa2 : 10,  total: 70 },
		    { posicao: 8,  jogador: 'Sara de moraes ', etapa1: 70,  etapa2 : 0,  total: 70 },
		    { posicao: 9,  jogador: 'Laise Miranda ', etapa1: 60,  etapa2 : 0,  total: 60 },
		                { posicao: 9,  jogador: 'MAIARA GUNTZEL ', etapa1: 0,  etapa2 : 60,  total: 60 },
		                { posicao: 9,  jogador: 'LUANA VIECILI SUZANO ', etapa1: 0,  etapa2 : 60,  total: 60 },
		                { posicao: 10,  jogador: 'Gabriela Echer ', etapa1: 10,  etapa2 : 40,  total: 50 },
		                { posicao: 10,  jogador: 'Gisela Bremm ', etapa1: 10,  etapa2 : 40,  total: 50 },
		                { posicao: 11,  jogador: 'Rachel Roos ', etapa1: 40,  etapa2 : 0,  total: 40 },
		    { posicao: 11,  jogador: 'Laise Miranda ', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 12,  jogador: 'Valentina Bonomo ', etapa1: 10,  etapa2 : 0,  total: 10 },
		    { posicao: 12,  jogador: 'Vanessa Tais Scheffler Ciechowicz Wissmann ', etapa1: 10,  etapa2 : 0,  total: 10 },
		    { posicao: 12,  jogador: 'Ana beatriz carpena ', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 12,  jogador: ' Mariana Braga Volkmer ', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 13,  jogador: ' Parceira ', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 13,  jogador: ' Marileia Sagrilo ', etapa1: 0,  etapa2 : 0,  total: 0 },


		   ]
		  }, {
		   categoria: '5ª FEMININA',
		   classificacao: [
		    { posicao: 1, jogador: 'Bianca Costa Beber', etapa1: 100,  etapa2 : 100,  total: 200 },
		                { posicao: 2, jogador: 'Lara Piccoli', etapa1: 40,  etapa2 : 100,  total: 140 },
		                { posicao: 3, jogador: 'Cristiane Klett', etapa1: 70,  etapa2 : 40,  total: 110 },
		                { posicao: 3, jogador: 'Patricia Goller', etapa1: 70,  etapa2 : 40,  total: 110 },
		                { posicao: 4, jogador: 'Bárbara Rücker Zanatta', etapa1: 100,  etapa2 : 0,  total: 100 },
		                { posicao: 4, jogador: 'Amanda borges pereira', etapa1: 40,  etapa2 : 60,  total: 100 },
		                { posicao: 5, jogador: 'Betina Crossetti', etapa1: 40,  etapa2 : 40,  total: 80 },
		                { posicao: 6, jogador: 'Alessandra Driwin', etapa1: 0,  etapa2 : 70,  total: 70 },
		                { posicao: 6, jogador: 'Juliana Lima', etapa1: 0,  etapa2 : 70,  total: 70 },
		                { posicao: 6, jogador: 'Sula Muriel Viecili Zardin', etapa1: 10,  etapa2 : 60,  total: 70 },
		                { posicao: 6, jogador: 'Estela Casotti', etapa1: 10,  etapa2 : 60,  total: 70 },
		                { posicao: 6, jogador: 'Janaína Agnoletto', etapa1: 10,  etapa2 : 60,  total: 70 },
		                { posicao: 6, jogador: 'Andressa Gressler Stumm', etapa1: 60,  etapa2 : 10,  total: 70 },
		                { posicao: 6, jogador: 'Ana Zardin Gehrke', etapa1: 60,  etapa2 : 10,  total: 70 },
		                { posicao: 7, jogador: 'LUANA VIECILI SUZANO', etapa1: 60,  etapa2 : 0,  total: 60 },
		                { posicao: 7, jogador: 'MAIARA GUNTZEL', etapa1: 60,  etapa2 : 0,  total: 60 },
		                { posicao: 8, jogador: 'Jaqueline Conrad Schirmer', etapa1: 10,  etapa2 : 40,  total: 50 },
		                { posicao: 9, jogador: 'Andressa Raugust', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 9, jogador: 'Jamile Lorenzen', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 9, jogador: 'Daniela Belochio', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 9, jogador: 'Lizandra Forgiarini Lucca', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 9, jogador: 'Taciane Goebel', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 9, jogador: 'Alessandra Wolanin', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 9, jogador: 'Deise Marin', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 9, jogador: 'Ana beatriz carpena', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 9, jogador: 'Juliana Garzella', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 10, jogador: 'Livia Otero', etapa1: 10,  etapa2 : 10,  total: 20 },
		                { posicao: 10, jogador: 'Tayane Balbé boligon', etapa1: 10,  etapa2 : 10,  total: 20 },
		                { posicao: 11, jogador: 'Ana Luiza Mantey', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 11, jogador: 'luciana behr', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 11, jogador: 'Laura Damian', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 11, jogador: 'Luiza Machado', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 12, jogador: 'Carina Echer', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 12, jogador: 'Fernanda Basilio Uggeri', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 12, jogador: 'Paola Bellé', etapa1: 0,  etapa2 : 0,  total: 0 },

		   ]
		  }, {
		   categoria: '6ª FEMININA',
		   classificacao: [
		                { posicao: 1,  jogador: ' Jerusa Dutra Schreiner', etapa1: 100,  etapa2 : 0,  total: 100 },
		                { posicao: 1,  jogador: ' Adriana Stocker Thomé da Cruz', etapa1: 60,  etapa2 : 40,  total: 100 },
		                { posicao: 1,  jogador: ' Cheila Pilan', etapa1: 100,  etapa2 : 0,  total: 100 },
		                { posicao: 1,  jogador: ' Tanise savaris schossler', etapa1: 0,  etapa2 : 100,  total: 100 },
		                { posicao: 1,  jogador: ' Rafaela Herter', etapa1: 0,  etapa2 : 100,  total: 100 },
		                { posicao: 1,  jogador: ' Dedilhana Manjabosco Hubner', etapa1: 60,  etapa2 : 40,  total: 100 },
		                { posicao: 1,  jogador: ' Juliana Beal', etapa1: 60,  etapa2 : 40,  total: 100 },
		                { posicao: 2,  jogador: ' Caroline Donini', etapa1: 40,  etapa2 : 40,  total: 80 },
		                { posicao: 2,  jogador: ' Camila R Camargo Goulart', etapa1: 40,  etapa2 : 40,  total: 80 },
		                { posicao: 2,  jogador: ' Fernanda Basilio Uggeri', etapa1: 40,  etapa2 : 40,  total: 80 },
		                { posicao: 3,  jogador: ' Cleusa ponte araujo', etapa1: 0,  etapa2 : 70,  total: 70 },
		                { posicao: 3,  jogador: ' Lizandra Forgiarini Lucca', etapa1: 70,  etapa2 : 0,  total: 70 },
		                { posicao: 3,  jogador: ' Tayna Rockenbach', etapa1: 0,  etapa2 : 70,  total: 70 },
		                { posicao: 3,  jogador: ' Daniela Belochio', etapa1: 70,  etapa2 : 0,  total: 70 },
		                { posicao: 4,  jogador: ' Marluce Ribeiro', etapa1: 0,  etapa2 : 60,  total: 60 },
		                { posicao: 4,  jogador: ' Raquel Heinle', etapa1: 0,  etapa2 : 60,  total: 60 },
		                { posicao: 4,  jogador: ' Raquel Fengler Liebich', etapa1: 30,  etapa2 : 30,  total: 60 },
		                { posicao: 4,  jogador: ' Priscila Herter Liebich Wendland', etapa1: 30,  etapa2 : 30,  total: 60 },
		                { posicao: 4,  jogador: ' Mariane Santos schefer', etapa1: 60,  etapa2 : 0,  total: 60 },
		                { posicao: 4,  jogador: ' Angélica Cardoso', etapa1: 0,  etapa2 : 60,  total: 60 },
		                { posicao: 4,  jogador: ' Manuela Gamarra', etapa1: 0,  etapa2 : 60,  total: 60 },
		                { posicao: 5,  jogador: ' Tamires Ritter', etapa1: 40,  etapa2 : 10,  total: 50 },
		                { posicao: 6,  jogador: ' Daniela Mapeli', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 6,  jogador: ' Mariane Klett', etapa1: 10,  etapa2 : 30,  total: 40 },
		                { posicao: 6,  jogador: ' Débora Cristiane Bruxel', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 6,  jogador: ' Cristiane de Pauli', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 6,  jogador: ' Carina Echer', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 6,  jogador: ' Caroline Fucks Darui', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 6,  jogador: ' Gabriele Krause', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 7,  jogador: ' Flávia Regina Machado Lausmann', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 7,  jogador: ' Viviane Godoi da Silva', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 7,  jogador: ' Tanara Reimann', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 7,  jogador: ' Kelly Berti kruger', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 7,  jogador: ' Bruna Silva Berti', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 7,  jogador: ' Marluce Junges Ribeiro', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 7,  jogador: ' Tatiane OLIVEIRA BERTÓ', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Cheila Spohr', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 8,  jogador: ' Milena Scherer Pizzuti', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 8,  jogador: ' Caroline Dalcin', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 8,  jogador: ' Taila Oliveira', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 8,  jogador: ' Bruna Szulczewski dos Santos', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 8,  jogador: ' ANA RITA WERNER PROTTI', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 8,  jogador: ' Camila Netto', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 8,  jogador: ' Amanda Amaral Sukert', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 8,  jogador: ' Patrícia Dallaglio', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 8,  jogador: ' Heverlin Grasiele Soares Guimarães', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 8,  jogador: ' Candice Viecili', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 8,  jogador: ' Fabiane Rocha', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 8,  jogador: ' Kely Zborowski Mews', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 8,  jogador: ' Simone Vaz', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 8,  jogador: ' Luciane Marisa Heuser', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 8,  jogador: ' Vivian Fischer Butignol', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 9,  jogador: ' Fabiane Piltz', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 9,  jogador: ' Rafaela Dumoncel', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 9,  jogador: ' Débora Gottert', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 9,  jogador: ' Rafaela Dutra Schmidt', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 9,  jogador: ' Dessana Becker Ghellar', etapa1: 0,  etapa2 : 0,  total: 0 },


		   ]
		  }, {
		   categoria: 'INICIANTES FEMININA',
		   classificacao: [
		    { posicao: 1,  jogador: ' Joana Parnoff Bellé Lucchese', etapa1: 70,  etapa2 : 60,  total: 130 },
		                { posicao: 1,  jogador: ' Priscila Dambros Bellé', etapa1: 70,  etapa2 : 60,  total: 130 },
		                { posicao: 1,  jogador: ' Ana Beatriz Costa Beber', etapa1: 30,  etapa2 : 100,  total: 130 },
		                { posicao: 1,  jogador: ' Kyara Wazlawick', etapa1: 30,  etapa2 : 100,  total: 130 },
		                { posicao: 2,  jogador: ' Caterine de Moura Brachtvogel', etapa1: 40,  etapa2 : 70,  total: 110 },
		                { posicao: 2,  jogador: ' Juliana Schrenk', etapa1: 40,  etapa2 : 70,  total: 110 },
		                { posicao: 3,  jogador: ' Camila Netto', etapa1: 100,  etapa2 : 0,  total: 100 },
		                { posicao: 3,  jogador: ' Maiara Basso Bilibio', etapa1: 60,  etapa2 : 40,  total: 100 },
		                { posicao: 3,  jogador: ' Bruna Szulczewski dos Santos', etapa1: 100,  etapa2 : 0,  total: 100 },
		                { posicao: 4,  jogador: ' Queli Cristiane Schiefelbein da Silva', etapa1: 40,  etapa2 : 40,  total: 80 },
		                { posicao: 4,  jogador: ' Micherle Oliveira Bandeira', etapa1: 40,  etapa2 : 40,  total: 80 },
		                { posicao: 4,  jogador: ' Simone Sfalcin tonielo', etapa1: 40,  etapa2 : 40,  total: 80 },
		                { posicao: 5,  jogador: ' Bianca Burmann', etapa1: 40,  etapa2 : 30,  total: 70 },
		                { posicao: 6,  jogador: ' Débora Cristiane Bruxel', etapa1: 60,  etapa2 : 0,  total: 60 },
		                { posicao: 6,  jogador: ' Marisandra Casali', etapa1: 60,  etapa2 : 0,  total: 60 },
		                { posicao: 6,  jogador: ' Manuela Gamarra', etapa1: 60,  etapa2 : 0,  total: 60 },
		                { posicao: 6,  jogador: ' Neide Andreia Amarante', etapa1: 0,  etapa2 : 60,  total: 60 },
		                { posicao: 6,  jogador: ' Alexandra schwerz', etapa1: 0,  etapa2 : 60,  total: 60 },
		                { posicao: 7,  jogador: ' Shana Vendrusculo', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 7,  jogador: ' Milena Schein', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 7,  jogador: ' Rosana Ferretti Zambra Gobo', etapa1: 10,  etapa2 : 30,  total: 40 },
		                { posicao: 7,  jogador: ' VANIA DE MORAES', etapa1: 10,  etapa2 : 30,  total: 40 },
		                { posicao: 7,  jogador: ' Fabiana Brand Fiorin', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 7,  jogador: ' Naiara Casarin', etapa1: 30,  etapa2 : 10,  total: 40 },
		                { posicao: 7,  jogador: ' Gabriele Weber Fuhrmann', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 7,  jogador: ' Luciana Faustini Pereira', etapa1: 40,  etapa2 : 0,  total: 40 },
		                { posicao: 7,  jogador: ' Monise Pezzi Baroni', etapa1: 0,  etapa2 : 40,  total: 40 },
		                { posicao: 7,  jogador: ' Cristiane ludtke heuser', etapa1: 10,  etapa2 : 30,  total: 40 },
		                { posicao: 8,  jogador: ' Shana Vendruscolo', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 8,  jogador: ' Gabriele Krause', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 8,  jogador: ' Ana Carolina Azambuja Copetti', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Débora Gottert', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Heverlin Grasiele Soares Guimarães', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Angelina Krause Poncio', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Silvana Giacomini', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Bianca Vaucher', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Caroline Vaucher', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Mônica Colombelli', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Vivian Fischer Butignol', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' ANA RITA WERNER PROTTI', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Tatiane Azambuja', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Tais Carvalho', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 8,  jogador: ' Luísa Iserhard Kurtz', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Cristiana Porto Sander', etapa1: 30,  etapa2 : 0,  total: 30 },
		                { posicao: 8,  jogador: ' Caroline Fucks Darui', etapa1: 0,  etapa2 : 30,  total: 30 },
		                { posicao: 9,  jogador: ' Thayana Kohler Muller', etapa1: 10,  etapa2 : 10,  total: 20 },
		                { posicao: 9,  jogador: ' Alexandra Decker', etapa1: 10,  etapa2 : 10,  total: 20 },
		                { posicao: 10,  jogador: ' Jordana Menegon', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 10,  jogador: ' Elisiane Machado', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 10,  jogador: ' Anna Líbera', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 10,  jogador: ' Rita beckel', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 10,  jogador: ' Eneida Vendruscollo', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 10,  jogador: ' Tamires Dressler', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 10,  jogador: ' Laís Schwarz', etapa1: 0,  etapa2 : 10,  total: 10 },
		                { posicao: 10,  jogador: ' Marta Dreier', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 10,  jogador: ' Luana Michael Jessica Tomio', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 10,  jogador: ' Mariana Fonseca e Silva', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 10,  jogador: ' Martha Fonseca Da Silva', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 10,  jogador: ' Mariana Rowe', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 10,  jogador: ' Samanta Basso', etapa1: 10,  etapa2 : 0,  total: 10 },
		                { posicao: 11,  jogador: ' Gabriel Machado', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 11,  jogador: ' Marcia Feltrin', etapa1: 0,  etapa2 : 0,  total: 0 },
		                { posicao: 11,  jogador: ' Isabelle Vitória Brand Camargo', etapa1: 0,  etapa2 : 0,  total: 0 },

		   ]
		  }, {

		                categoria: '3ª MASCULINA',
		                classificacao: [
		                    { posicao: 1, jogador: ' Douglas Volkmer', etapa1: 100, etapa2 : 100, total: 200 },
		                    { posicao: 2, jogador: ' Enzo Schirmer', etapa1: 60, etapa2 : 60, total: 120 },
		                    { posicao: 2, jogador: ' Vinícius Fontoura', etapa1: 60, etapa2 : 60, total: 120 },
		                    { posicao: 3, jogador: ' Amâncio Galeazzi', etapa1: 0, etapa2 : 100, total: 100 },
		                    { posicao: 3, jogador: ' Marcelo Oliveira', etapa1: 100, etapa2 : 0, total: 100 },
		                    { posicao: 4, jogador: ' LUCAS GOULART DA SILVA', etapa1: 40, etapa2 : 40, total: 80 },
		                    { posicao: 5, jogador: ' Marcel Millani', etapa1: 70, etapa2 : 0, total: 70 },
		                    { posicao: 5, jogador: ' Diovani Matos', etapa1: 70, etapa2 : 0, total: 70 },
		                    { posicao: 5, jogador: ' Leonardo coracini', etapa1: 0, etapa2 : 70, total: 70 },
		                    { posicao: 5, jogador: ' Parceiro Parceiro', etapa1: 0, etapa2 : 70, total: 70 },
		                    { posicao: 6, jogador: ' Leonardo Coracini', etapa1: 60, etapa2 : 0, total: 60 },
		                    { posicao: 6, jogador: ' Vinicius Garcia', etapa1: 60, etapa2 : 0, total: 60 },
		                    { posicao: 6, jogador: ' Clandio Marcolan Suzano', etapa1: 0, etapa2 : 60, total: 60 },
		                    { posicao: 6, jogador: ' Júlio Beal', etapa1: 0, etapa2 : 60, total: 60 },
		                    { posicao: 7, jogador: ' Tainan Pizzolato Minuzzi', etapa1: 10, etapa2 : 40, total: 50 },
		                    { posicao: 7, jogador: ' Alan Colaço', etapa1: 10, etapa2 : 40, total: 50 },
		                    { posicao: 7, jogador: ' Marcelo Batista', etapa1: 40, etapa2 : 10, total: 50 },
		                    { posicao: 7, jogador: ' Adilmar M Almeida', etapa1: 40, etapa2 : 10, total: 50 },
		                    { posicao: 7, jogador: ' Jeber El Ammar', etapa1: 40, etapa2 : 10, total: 50 },
		                    { posicao: 8, jogador: ' Lucas jovanovichs', etapa1: 10, etapa2 : 30, total: 40 },
		                    { posicao: 8, jogador: ' Alexandre Bastos', etapa1: 40, etapa2 : 0, total: 40 },
		                    { posicao: 8, jogador: ' Tiziano Rorato', etapa1: 0, etapa2 : 40, total: 40 },
		                    { posicao: 8, jogador: ' Pedro de Medeiros', etapa1: 0, etapa2 : 40, total: 40 },
		                    { posicao: 8, jogador: ' Lucas Martins', etapa1: 0, etapa2 : 40, total: 40 },
		                    { posicao: 8, jogador: ' Maurício Copetti', etapa1: 0, etapa2 : 40, total: 40 },
		                    { posicao: 8, jogador: ' William Amaro', etapa1: 0, etapa2 : 40, total: 40 },
		                    { posicao: 8, jogador: ' MAURICIO JOSE COPETTI', etapa1: 40, etapa2 : 0, total: 40 },
		                    { posicao: 8, jogador: ' Tiago Neto', etapa1: 40, etapa2 : 0, total: 40 },
		                    { posicao: 8, jogador: ' Edmundo Kliemann', etapa1: 40, etapa2 : 0, total: 40 },
		                    { posicao: 9, jogador: ' Rafael Knack', etapa1: 0, etapa2 : 30, total: 30 },
		                    { posicao: 9, jogador: ' Alex S. Batista', etapa1: 0, etapa2 : 30, total: 30 },
		                    { posicao: 9, jogador: ' gabriel kraus schubert', etapa1: 0, etapa2 : 30, total: 30 },
		                    { posicao: 9, jogador: ' Jeferson Mardini Cokinho', etapa1: 0, etapa2 : 30, total: 30 },
		                    { posicao: 9, jogador: ' Guilhermo Soldera', etapa1: 0, etapa2 : 30, total: 30 },
		                    { posicao: 9, jogador: ' Diego castel lopes', etapa1: 0, etapa2 : 30, total: 30 },
		                    { posicao: 9, jogador: ' Gabriel Loro', etapa1: 0, etapa2 : 30, total: 30 },
		                    { posicao: 10, jogador: ' Thiago Oliveira', etapa1: 10, etapa2 : 10, total: 20 },
		                    { posicao: 11, jogador: ' Augusto Grimm', etapa1: 10, etapa2 : 0, total: 10 },
		                    { posicao: 11, jogador: ' aga881 EDMUNDO KLIEMANN EPP', etapa1: 0, etapa2 : 10, total: 10 },
		                    { posicao: 11, jogador: ' Nathan Nunes', etapa1: 10, etapa2 : 0, total: 10 },
		                    { posicao: 11, jogador: ' Glaucio Schwerz Zimmermann', etapa1: 10, etapa2 : 0, total: 10 },
		                    { posicao: 11, jogador: ' saulo schneider', etapa1: 0, etapa2 : 10, total: 10 },
		                    { posicao: 11, jogador: ' Mathias Sulzbach', etapa1: 10, etapa2 : 0, total: 10 },
		                    { posicao: 11, jogador: ' Alexandre Bastos', etapa1: 0, etapa2 : 10, total: 10 },
		                    { posicao: 11, jogador: ' Manuel Souza', etapa1: 0, etapa2 : 10, total: 10 },
		                    { posicao: 12, jogador: ' Guilherme Trentim', etapa1: 0, etapa2 : 0, total: 0 },
		                    { posicao: 12, jogador: ' Júlio Coracini', etapa1: 0, etapa2 : 0, total: 0 },
		                    { posicao: 12, jogador: ' Luciano Goulart', etapa1: 0, etapa2 : 0, total: 0 },
		                    { posicao: 12, jogador: ' Alisson Soares', etapa1: 0, etapa2 : 0, total: 0 },
		                    { posicao: 12, jogador: ' Marcelo Furtado Pereira Morales', etapa1: 0, etapa2 : 0, total: 0 },

		                ]
		                }, {
		                    categoria: '4ª MASCULINA',
		                    classificacao: [
		                        { posicao: 1, jogador: ' Lucas Almeida', etapa1: 100, etapa2 : 100, total: 200 },
		                        { posicao: 2, jogador: ' ALISSON ROCHA', etapa1: 60, etapa2 : 60, total: 120 },
		                        { posicao: 2, jogador: ' TASSIO FORGIARINI', etapa1: 60, etapa2 : 60, total: 120 },
		                        { posicao: 3, jogador: ' Guilherme Nonnemacher', etapa1: 100, etapa2 : 0, total: 100 },
		                        { posicao: 3, jogador: ' Mathias Schultz', etapa1: 60, etapa2 : 40, total: 100 },
		                        { posicao: 3, jogador: ' Gustavo Knack', etapa1: 60, etapa2 : 40, total: 100 },
		                        { posicao: 3, jogador: ' Mateus Fontella Teixeira', etapa1: 0, etapa2 : 100, total: 100 },
		                        { posicao: 4, jogador: ' Roberto Gendelski', etapa1: 0, etapa2 : 70, total: 70 },
		                        { posicao: 4, jogador: ' Luiz Ricardo Radaelli', etapa1: 0, etapa2 : 70, total: 70 },
		                        { posicao: 4, jogador: ' Diego pasqualoto', etapa1: 40, etapa2 : 30, total: 70 },
		                        { posicao: 4, jogador: ' Luis Ricardo radaeli', etapa1: 70, etapa2 : 0, total: 70 },
		                        { posicao: 4, jogador: ' Thiago burtet', etapa1: 40, etapa2 : 30, total: 70 },
		                        { posicao: 4, jogador: ' Diego castel lopes', etapa1: 70, etapa2 : 0, total: 70 },
		                        { posicao: 5, jogador: ' Rogério Vilani', etapa1: 0, etapa2 : 60, total: 60 },
		                        { posicao: 5, jogador: ' Marcelo Kulmann Cleveston', etapa1: 0, etapa2 : 60, total: 60 },
		                        { posicao: 6, jogador: ' Marcos Aurélio Boniatti Guimarães', etapa1: 10, etapa2 : 40, total: 50 },
		                        { posicao: 6, jogador: ' Felipe Hammarstron', etapa1: 40, etapa2 : 10, total: 50 },
		                        { posicao: 7, jogador: ' Rafael Manhabosco Moraes', etapa1: 40, etapa2 : 0, total: 40 },
		                        { posicao: 7, jogador: ' Felipe Miranda', etapa1: 0, etapa2 : 40, total: 40 },
		                        { posicao: 7, jogador: ' Kevin Mielke Berti', etapa1: 0, etapa2 : 40, total: 40 },
		                        { posicao: 7, jogador: ' Luciano Benjamin Bastos', etapa1: 0, etapa2 : 40, total: 40 },
		                        { posicao: 7, jogador: ' Fabian Marquez', etapa1: 0, etapa2 : 40, total: 40 },
		                        { posicao: 7, jogador: ' José de Souza Reali', etapa1: 0, etapa2 : 40, total: 40 },
		                        { posicao: 8, jogador: ' Gilberto R Naressi', etapa1: 0, etapa2 : 30, total: 30 },
		                        { posicao: 8, jogador: ' Marcos L Rodrigues', etapa1: 0, etapa2 : 30, total: 30 },
		                        { posicao: 9, jogador: ' Guilherme Corrêa', etapa1: 10, etapa2 : 10, total: 20 },
		                        { posicao: 10, jogador: ' Enzo Rodrigo Schirmer Filho', etapa1: 0, etapa2 : 10, total: 10 },
		                        { posicao: 10, jogador: ' Patrick Nader', etapa1: 0, etapa2 : 10, total: 10 },
		                        { posicao: 10, jogador: ' Everson Rocha', etapa1: 0, etapa2 : 10, total: 10 },
		                        { posicao: 10, jogador: ' Thiago debacco perini', etapa1: 0, etapa2 : 10, total: 10 },
		                        { posicao: 10, jogador: ' Augusto Grimm', etapa1: 0, etapa2 : 10, total: 10 },
		                        { posicao: 10, jogador: ' Nathan Nunes', etapa1: 0, etapa2 : 10, total: 10 },
		                        { posicao: 10, jogador: ' Eduardo Buss', etapa1: 10, etapa2 : 0, total: 10 },
		                        { posicao: 10, jogador: ' Juliano Beck', etapa1: 10, etapa2 : 0, total: 10 },
		                        { posicao: 10, jogador: ' André Teles Drews', etapa1: 10, etapa2 : 0, total: 10 },
		                        { posicao: 10, jogador: ' Clandio Marcolan Suzano', etapa1: 10, etapa2 : 0, total: 10 },
		                        { posicao: 11, jogador: ' Gennaro Balzan', etapa1: 0, etapa2 : 0, total: 0 },

		                    ]
		                    }, {
		                        categoria: '5ª MASCULINA',
		                        classificacao: [
		                            { posicao: 1, jogador: ' Tiago Filippin', etapa1: 100, etapa2 : 60, total: 160 },
		                            { posicao: 1, jogador: ' Arlan Rohenkohl', etapa1: 100, etapa2 : 60, total: 160 },
		                            { posicao: 2, jogador: ' Gunar Natan Viecili', etapa1: 60, etapa2 : 70, total: 130 },
		                            { posicao: 3, jogador: ' Fabrício Agnoleto', etapa1: 60, etapa2 : 60, total: 120 },
		                            { posicao: 4, jogador: ' Junos Kruger', etapa1: 70, etapa2 : 40, total: 110 },
		                            { posicao: 5, jogador: ' Thales Gutterres Moroni', etapa1: 0, etapa2 : 100, total: 100 },
		                            { posicao: 5, jogador: ' Rodrigo Gutterres Moroni', etapa1: 0, etapa2 : 100, total: 100 },
		                            { posicao: 6, jogador: ' Gustavo Dalligna', etapa1: 30, etapa2 : 60, total: 90 },
		                            { posicao: 7, jogador: ' TIAGO TODESCHINI ROEHRS', etapa1: 40, etapa2 : 40, total: 80 },
		                            { posicao: 7, jogador: ' LEONARDO EICKHOFF BIGOLIN', etapa1: 40, etapa2 : 40, total: 80 },
		                            { posicao: 7, jogador: ' Vinicius Milaneze', etapa1: 40, etapa2 : 40, total: 80 },
		                            { posicao: 7, jogador: ' Douglas Fiorin', etapa1: 40, etapa2 : 40, total: 80 },
		                            { posicao: 8, jogador: ' Kevin Mielke Berti', etapa1: 70, etapa2 : 0, total: 70 },
		                            { posicao: 8, jogador: ' Júnior Jardim Mensch', etapa1: 0, etapa2 : 70, total: 70 },
		                            { posicao: 8, jogador: ' Tomás Trindade Lopes', etapa1: 60, etapa2 : 10, total: 70 },
		                            { posicao: 9, jogador: ' Ivan Barbosa dos Reis', etapa1: 60, etapa2 : 0, total: 60 },
		                            { posicao: 10, jogador: ' Mateus Przybitowicz Martini', etapa1: 40, etapa2 : 10, total: 50 },
		                            { posicao: 11, jogador: ' Leonardo da silva pizzuti', etapa1: 0, etapa2 : 40, total: 40 },
		                            { posicao: 11, jogador: ' Diego Zardin', etapa1: 0, etapa2 : 40, total: 40 },
		                            { posicao: 11, jogador: ' André Teles Drews', etapa1: 0, etapa2 : 40, total: 40 },
		                            { posicao: 11, jogador: ' Francisco dei ricardi Sandri', etapa1: 10, etapa2 : 30, total: 40 },
		                            { posicao: 11, jogador: ' Guilherme Kurtz', etapa1: 10, etapa2 : 30, total: 40 },
		                            { posicao: 11, jogador: ' Daniel Ottonelli', etapa1: 40, etapa2 : 0, total: 40 },
		                            { posicao: 11, jogador: ' Wagner Herter Hernandez', etapa1: 40, etapa2 : 0, total: 40 },
		                            { posicao: 11, jogador: ' Felipe Gastaldo', etapa1: 40, etapa2 : 0, total: 40 },
		                            { posicao: 11, jogador: ' Fabio Nass', etapa1: 30, etapa2 : 10, total: 40 },
		                            { posicao: 12, jogador: ' Diego Zardin', etapa1: 30, etapa2 : 0, total: 30 },
		                            { posicao: 12, jogador: ' Carlos Eduardo Faccin Zanchi', etapa1: 0, etapa2 : 30, total: 30 },
		                            { posicao: 12, jogador: ' Oliver Beck', etapa1: 0, etapa2 : 30, total: 30 },
		                            { posicao: 12, jogador: ' Juliano Beck', etapa1: 0, etapa2 : 30, total: 30 },
		                            { posicao: 12, jogador: ' Mauricio Panazzolo', etapa1: 30, etapa2 : 0, total: 30 },
		                            { posicao: 12, jogador: ' Arthur Herter Liebich', etapa1: 0, etapa2 : 30, total: 30 },
		                            { posicao: 12, jogador: ' Paulo Stumm', etapa1: 30, etapa2 : 0, total: 30 },
		                            { posicao: 12, jogador: ' Gustavo Goelzer Gobo', etapa1: 0, etapa2 : 30, total: 30 },
		                            { posicao: 12, jogador: ' Guilherme Liebich', etapa1: 0, etapa2 : 30, total: 30 },
		                            { posicao: 12, jogador: ' Bruno Cardias', etapa1: 30, etapa2 : 0, total: 30 },
		                            { posicao: 12, jogador: ' Everson Rocha', etapa1: 30, etapa2 : 0, total: 30 },
		                            { posicao: 12, jogador: ' Enzo Filho', etapa1: 30, etapa2 : 0, total: 30 },
		                            { posicao: 13, jogador: ' Rodrigo Mastella', etapa1: 10, etapa2 : 10, total: 20 },
		                            { posicao: 14, jogador: ' Edson Schwerz', etapa1: 0, etapa2 : 10, total: 10 },
		                            { posicao: 14, jogador: ' Matias Da Costa', etapa1: 0, etapa2 : 10, total: 10 },
		                            { posicao: 14, jogador: ' Pedro Sodré Rubenich', etapa1: 0, etapa2 : 10, total: 10 },
		                            { posicao: 14, jogador: ' João Gustavo De Quadros Farina', etapa1: 0, etapa2 : 10, total: 10 },
		                            { posicao: 14, jogador: ' Guilherme Walker', etapa1: 0, etapa2 : 10, total: 10 },
		                            { posicao: 14, jogador: ' joao guilherme bicudo', etapa1: 0, etapa2 : 10, total: 10 },
		                            { posicao: 14, jogador: ' Matheus peukert', etapa1: 0, etapa2 : 10, total: 10 },
		                            { posicao: 14, jogador: ' Maicon Provenzi', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Leonardo Vieira', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Gabriel Finatto', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Eduardo Pohlmann', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Rafael Goulart', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Didio Neto', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Roger Costa Beber', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Gennaro Balzan', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Glauber Zanon', etapa1: 10, etapa2 : 0, total: 10 },
		                            { posicao: 14, jogador: ' Juliano Lisboa', etapa1: 0, etapa2 : 10, total: 10 },
		                            { posicao: 15, jogador: ' Alexandre Paludo', etapa1: 0, etapa2 : 0, total: 0 },


		                        ]
		                        }, {
		                            categoria: '6ª MASCULINA',
		                            classificacao: [
		                                { posicao: 1, jogador: ' Luiz Felipe Pes', etapa1: 40, etapa2 : 100, total: 140 },
		                                { posicao: 2, jogador: ' Guilherme Liebich', etapa1: 100, etapa2 : 0, total: 100 },
		                                { posicao: 2, jogador: ' Jader Sperotto Ferrazza', etapa1: 0, etapa2 : 100, total: 100 },
		                                { posicao: 2, jogador: ' Arthur Herter Liebich', etapa1: 100, etapa2 : 0, total: 100 },
		                                { posicao: 3, jogador: ' Gabriel Machado', etapa1: 70, etapa2 : 0, total: 70 },
		                                { posicao: 3, jogador: ' Henrique Batista dos Santos', etapa1: 70, etapa2 : 0, total: 70 },
		                                { posicao: 3, jogador: ' João Girardi', etapa1: 30, etapa2 : 40, total: 70 },
		                                { posicao: 3, jogador: ' Fernando Iserhard', etapa1: 0, etapa2 : 70, total: 70 },
		                                { posicao: 3, jogador: ' Rui Antônio Ledur Wendt Filho', etapa1: 0, etapa2 : 70, total: 70 },
		                                { posicao: 4, jogador: ' Rafael Bortoluzzi Corrêa', etapa1: 30, etapa2 : 30, total: 60 },
		                                { posicao: 4, jogador: ' ivan venzo', etapa1: 30, etapa2 : 30, total: 60 },
		                                { posicao: 4, jogador: ' Alessandro Guterres Lucca', etapa1: 0, etapa2 : 60, total: 60 },
		                                { posicao: 4, jogador: ' Pedro Ivo Martiny', etapa1: 0, etapa2 : 60, total: 60 },
		                                { posicao: 4, jogador: ' Gabriel Machado', etapa1: 0, etapa2 : 60, total: 60 },
		                                { posicao: 4, jogador: ' João vicente Martiny', etapa1: 0, etapa2 : 60, total: 60 },
		                                { posicao: 4, jogador: ' Rodrigo bonfada gelatti', etapa1: 60, etapa2 : 0, total: 60 },
		                                { posicao: 4, jogador: ' Vitor Martins Wojciechowski', etapa1: 60, etapa2 : 0, total: 60 },
		                                { posicao: 4, jogador: ' Edson Schwerz', etapa1: 60, etapa2 : 0, total: 60 },
		                                { posicao: 4, jogador: ' Matheus peukert', etapa1: 60, etapa2 : 0, total: 60 },
		                                { posicao: 5, jogador: ' Juan Monaco', etapa1: 10, etapa2 : 40, total: 50 },
		                                { posicao: 5, jogador: ' William Sulzbach', etapa1: 10, etapa2 : 40, total: 50 },
		                                { posicao: 6, jogador: ' Dudi Buss', etapa1: 0, etapa2 : 40, total: 40 },
		                                { posicao: 6, jogador: ' Everton Heeller', etapa1: 0, etapa2 : 40, total: 40 },
		                                { posicao: 6, jogador: ' Tainan Dos santos Camargo', etapa1: 0, etapa2 : 40, total: 40 },
		                                { posicao: 6, jogador: ' Luiz Lemes Machado', etapa1: 0, etapa2 : 40, total: 40 },
		                                { posicao: 6, jogador: ' Tarso Guilherme Mentges', etapa1: 0, etapa2 : 40, total: 40 },
		                                { posicao: 6, jogador: ' Valterson Wottrich', etapa1: 10, etapa2 : 30, total: 40 },
		                                { posicao: 6, jogador: ' Marcel Stucky', etapa1: 40, etapa2 : 0, total: 40 },
		                                { posicao: 6, jogador: ' Carlos Eduardo Faccin Zanchi', etapa1: 40, etapa2 : 0, total: 40 },
		                                { posicao: 6, jogador: ' Lucas Bromberger Martins', etapa1: 40, etapa2 : 0, total: 40 },
		                                { posicao: 6, jogador: ' Leonardo Sausen', etapa1: 40, etapa2 : 0, total: 40 },
		                                { posicao: 6, jogador: ' Vinnicius Wallau', etapa1: 40, etapa2 : 0, total: 40 },
		                                { posicao: 6, jogador: ' Jader Sperotto Ferrazza', etapa1: 40, etapa2 : 0, total: 40 },
		                                { posicao: 6, jogador: ' Vinicius Boaro', etapa1: 40, etapa2 : 0, total: 40 },
		                                { posicao: 6, jogador: ' ANDERSON FELIPE SIKACZ', etapa1: 30, etapa2 : 10, total: 40 },
		                                { posicao: 7, jogador: ' Leandro Hermann', etapa1: 30, etapa2 : 0, total: 30 },
		                                { posicao: 7, jogador: ' Áimann Shariff Ata Ramadan', etapa1: 30, etapa2 : 0, total: 30 },
		                                { posicao: 7, jogador: ' benhur vione', etapa1: 30, etapa2 : 0, total: 30 },
		                                { posicao: 7, jogador: ' Oliver Beck', etapa1: 30, etapa2 : 0, total: 30 },
		                                { posicao: 7, jogador: ' Arthur Guimarães Becker', etapa1: 0, etapa2 : 30, total: 30 },
		                                { posicao: 8, jogador: ' Otávio Grimm', etapa1: 10, etapa2 : 10, total: 20 },
		                                { posicao: 9, jogador: ' Lucas Emanuel Dransfeld', etapa1: 0, etapa2 : 10, total: 10 },
		                                { posicao: 9, jogador: ' Gustavo Catto Vaz', etapa1: 0, etapa2 : 10, total: 10 },
		                                { posicao: 9, jogador: ' Marcos Ribeiro', etapa1: 0, etapa2 : 10, total: 10 },
		                                { posicao: 9, jogador: ' Ricardo Dornelles', etapa1: 0, etapa2 : 10, total: 10 },
		                                { posicao: 9, jogador: ' Gianpaoli oliveira Brand', etapa1: 0, etapa2 : 10, total: 10 },
		                                { posicao: 9, jogador: ' João Guilherme', etapa1: 0, etapa2 : 10, total: 10 },
		                                { posicao: 9, jogador: ' Mateus fernando de brittes', etapa1: 0, etapa2 : 10, total: 10 },
		                                { posicao: 9, jogador: ' Guilherme Meyer', etapa1: 0, etapa2 : 10, total: 10 },
		                                { posicao: 9, jogador: ' Mateus Scherer', etapa1: 10, etapa2 : 0, total: 10 },
		                                { posicao: 9, jogador: ' Izidro Cabrera', etapa1: 10, etapa2 : 0, total: 10 },
		                                { posicao: 9, jogador: ' Pedro Sodré Rubenich', etapa1: 10, etapa2 : 0, total: 10 },
		                                { posicao: 9, jogador: ' Alan Beal Nyland', etapa1: 10, etapa2 : 0, total: 10 },
		                                { posicao: 9, jogador: ' Luiz Augusto Utzig Dumoncel', etapa1: 10, etapa2 : 0, total: 10 },
		                                { posicao: 9, jogador: ' Marcelo Dumoncel Tagliari', etapa1: 10, etapa2 : 0, total: 10 },
		                                { posicao: 9, jogador: ' Luís Carlos Zambonato', etapa1: 10, etapa2 : 0, total: 10 },
		                                { posicao: 9, jogador: ' Jedersom Altmeier', etapa1: 10, etapa2 : 0, total: 10 },
		                                { posicao: 10, jogador: ' Kiko Dalcin', etapa1: 0, etapa2 : 0, total: 0 },
		                                { posicao: 10, jogador: ' Hussein Hatem', etapa1: 0, etapa2 : 0, total: 0 },
		                                { posicao: 10, jogador: ' Leandro hermanns', etapa1: 0, etapa2 : 0, total: 0 },
		                                { posicao: 10, jogador: ' Alysson Andre de Ramos de Oliveira', etapa1: 0, etapa2 : 0, total: 0 },
		                                { posicao: 10, jogador: ' Kelvin Perdorsin', etapa1: 0, etapa2 : 0, total: 0 },

		                            ]
		                            }, {
		                                categoria: 'INICIANTES MASCULINA',
		                                classificacao: [
		                                    { posicao: 1, jogador: ' Ygor Aguiar Busatta', etapa1: 60, etapa2 : 100, total: 160 },
		                                    { posicao: 2, jogador: ' Geordano Tambara', etapa1: 40, etapa2 : 70, total: 110 },
		                                    { posicao: 2, jogador: ' Evandro Schindler', etapa1: 40, etapa2 : 70, total: 110 },
		                                    { posicao: 3, jogador: ' Marcos Ribeiro', etapa1: 100, etapa2 : 0, total: 100 },
		                                    { posicao: 3, jogador: ' Ricardo Dornelles', etapa1: 100, etapa2 : 0, total: 100 },
		                                    { posicao: 3, jogador: ' Felipe dambros', etapa1: 0, etapa2 : 100, total: 100 },
		                                    { posicao: 4, jogador: ' Everton Bruna Heeller', etapa1: 70, etapa2 : 0, total: 70 },
		                                    { posicao: 4, jogador: ' Tainan Dos santos Camargo', etapa1: 70, etapa2 : 0, total: 70 },
		                                    { posicao: 5, jogador: ' MARCOS DAGOBERTO SUKERT', etapa1: 60, etapa2 : 0, total: 60 },
		                                    { posicao: 5, jogador: ' Guilherme andrew somavila', etapa1: 60, etapa2 : 0, total: 60 },
		                                    { posicao: 5, jogador: ' Tairo rafael Palharini da Silva', etapa1: 60, etapa2 : 0, total: 60 },
		                                    { posicao: 5, jogador: ' MOHAMAD MOUNIR HATEM', etapa1: 0, etapa2 : 60, total: 60 },
		                                    { posicao: 5, jogador: ' Maicon Provenzi', etapa1: 0, etapa2 : 60, total: 60 },
		                                    { posicao: 5, jogador: ' Alessandro Rodrigo Haas', etapa1: 0, etapa2 : 60, total: 60 },
		                                    { posicao: 5, jogador: ' Gregori Hernandes Biniek', etapa1: 0, etapa2 : 60, total: 60 },
		                                    { posicao: 6, jogador: ' Maurício Dalla Rosa', etapa1: 10, etapa2 : 40, total: 50 },
		                                    { posicao: 7, jogador: ' Tiago Uecker', etapa1: 40, etapa2 : 0, total: 40 },
		                                    { posicao: 7, jogador: ' Igor Norbert Soares', etapa1: 0, etapa2 : 40, total: 40 },
		                                    { posicao: 7, jogador: ' guilherme costabeber', etapa1: 30, etapa2 : 10, total: 40 },
		                                    { posicao: 7, jogador: ' Alysson Andre de Ramos de Oliveira', etapa1: 0, etapa2 : 40, total: 40 },
		                                    { posicao: 7, jogador: ' Rickson Bairros', etapa1: 0, etapa2 : 40, total: 40 },
		                                    { posicao: 7, jogador: ' Lucas Augusto Gusso Hasse', etapa1: 0, etapa2 : 40, total: 40 },
		                                    { posicao: 7, jogador: ' Gustavo Zientarski postay', etapa1: 0, etapa2 : 40, total: 40 },
		                                    { posicao: 7, jogador: ' Alexandre Domingues', etapa1: 0, etapa2 : 40, total: 40 },
		                                    { posicao: 7, jogador: ' Luiz Affonso Lasta', etapa1: 0, etapa2 : 40, total: 40 },
		                                    { posicao: 7, jogador: ' Henrique Deboni Sandri', etapa1: 40, etapa2 : 0, total: 40 },
		                                    { posicao: 7, jogador: ' Marcos Franke', etapa1: 40, etapa2 : 0, total: 40 },
		                                    { posicao: 7, jogador: ' Nilton Coser', etapa1: 40, etapa2 : 0, total: 40 },
		                                    { posicao: 7, jogador: ' Mateus Lazari', etapa1: 40, etapa2 : 0, total: 40 },
		                                    { posicao: 7, jogador: ' Paulo Bitencourt', etapa1: 40, etapa2 : 0, total: 40 },
		                                    { posicao: 8, jogador: ' Leandro Riel Weber', etapa1: 0, etapa2 : 30, total: 30 },
		                                    { posicao: 8, jogador: ' Pedro Henrique Signori', etapa1: 0, etapa2 : 30, total: 30 },
		                                    { posicao: 8, jogador: ' PAULO ROBERTO GOI FILHO', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Marcelo Dumoncel Tagliari', etapa1: 0, etapa2 : 30, total: 30 },
		                                    { posicao: 8, jogador: ' Maurício Maciel Oliveira', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Rodrigo Picolo Goin', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Gustavo Schaffazick', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Henrique Wissmann', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Rui Antônio Ledur Wendt Filho', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Victor Toledo', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Gustavo Catto Vaz', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Clevio Tosetto', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Andre Egidio', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' RÉGIS DE MATOS', etapa1: 30, etapa2 : 0, total: 30 },
		                                    { posicao: 8, jogador: ' Luiz Augusto Utzig Dumoncel', etapa1: 0, etapa2 : 30, total: 30 },
		                                    { posicao: 9, jogador: ' Théo Forgiarini', etapa1: 10, etapa2 : 10, total: 20 },
		                                    { posicao: 10, jogador: ' André Moss', etapa1: 0, etapa2 : 10, total: 10 },
		                                    { posicao: 10, jogador: ' Lucas Borgmann da Silva', etapa1: 0, etapa2 : 10, total: 10 },
		                                    { posicao: 10, jogador: ' Victor Rodrigues da Silva', etapa1: 0, etapa2 : 10, total: 10 },
		                                    { posicao: 10, jogador: ' Rudolfo Pisccinin ortmann', etapa1: 0, etapa2 : 10, total: 10 },
		                                    { posicao: 10, jogador: ' Rafael Moreno de Oliveira', etapa1: 0, etapa2 : 10, total: 10 },
		                                    { posicao: 10, jogador: ' Vitor Sidnei Rey de Oliveira', etapa1: 0, etapa2 : 10, total: 10 },
		                                    { posicao: 10, jogador: ' Marcelo Correa de Oliveira', etapa1: 0, etapa2 : 10, total: 10 },
		                                    { posicao: 10, jogador: ' Bento coppetti schmalz', etapa1: 0, etapa2 : 10, total: 10 },
		                                    { posicao: 10, jogador: ' Talysson Dalmas', etapa1: 10, etapa2 : 0, total: 10 },
		                                    { posicao: 10, jogador: ' Arthur Guimaraes', etapa1: 10, etapa2 : 0, total: 10 },
		                                    { posicao: 10, jogador: ' Leonardo Wendland', etapa1: 10, etapa2 : 0, total: 10 },
		                                    { posicao: 10, jogador: ' Frederico Fogaça Herter', etapa1: 10, etapa2 : 0, total: 10 },
		                                    { posicao: 10, jogador: ' Natan Marquioro', etapa1: 10, etapa2 : 0, total: 10 },
		                                    { posicao: 10, jogador: ' Luiz Affonso Lasta', etapa1: 10, etapa2 : 0, total: 10 },
		                                    { posicao: 10, jogador: ' zoiio_chapad0', etapa1: 10, etapa2 : 0, total: 10 },
		                                    { posicao: 10, jogador: ' Adriano Funck dos Santos', etapa1: 10, etapa2 : 0, total: 10 },
		                                    { posicao: 11, jogador: ' Rafael Augusto Do Nascimento', etapa1: 0, etapa2 : 0, total: 0 },
		                                    { posicao: 11, jogador: ' Junior Dalla Rosa', etapa1: 0, etapa2 : 0, total: 0 },


		                                ]
		  }]
	}

	constructor(props) {
		super(props);
    }

    componentWillMount() {

    }

    render() {

        return (
			<Content>
				<Row>
					<Grid cols='6 6 4 2'>
						<Button
							text='Ver Ranking'
							type={'primary'}
							icon={'fa fa-plus'}
							event={() => {
								const linkSource = 'https://api-golden.infinitum-ti.com/pdf/ranking.pdf';
								const downloadLink = document.createElement('a');
								const fileName = 'Ranking.pdf';
								downloadLink.href = linkSource;
								downloadLink.target = '_blank';
								downloadLink.download = fileName;
								downloadLink.click();
							}} />
					</Grid>
					
					{/*this.state.lista.map((item, i) => (
						<Grid key={i} cols='12 12 6 4'>
							<ContentCard>
								<ContentCardHeader>
									<h4 class='box-title' style={{ textAlign: 'center' }}>{item.categoria}</h4>
								</ContentCardHeader>
								<ContentCardBody>
									<Table responsive>
										<THead>
											<Tr>
												<Th></Th>
												<Th>Jogador</Th>
												<Th alignCenter>Etapa 1</Th>
												<Th alignCenter>Etapa 2</Th>
												<Th alignCenter>Total</Th>
											</Tr>
										</THead>
										<TBody>
											{item.classificacao.map((classificacaoItem, j) =>
												<Tr key={j}>
													<Td width={50}>{classificacaoItem.posicao}</Td>
													<Td>{classificacaoItem.jogador}</Td>
													<Td alignCenter>{classificacaoItem.etapa1}</Td>
													<Td alignCenter>{classificacaoItem.etapa2}</Td>
													<Td alignCenter>{classificacaoItem.total}</Td>
												</Tr>
											)}
										</TBody>
									</Table>
								</ContentCardBody>
							</ContentCard>
						</Grid>
					))*/}
				</Row>
			</Content>
        )
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Classificacao)
