import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import axios from 'axios';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'USER_MODO_TELA',
        payload: {
			modo: modo
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('authForm', registro)
    ];
}

export function login(values) {
	return submit(values, `${consts.OAPI_URL}/usuario/login`);
}

export function signup(values) {
	return submit({
		...values,
		telefone: (values.telefone || '').replace(/\D/g, ''),
		cpf: (values.cpf || '').replace(/\D/g, ''),
	}, `${consts.OAPI_URL}/usuario/signup`);
}

function submit(values, url) {
	return dispatch => {

		if (values.alterar_senha) {
			if (values.codigo_gerado != values.codigo_validacao) {
				dispatch([{
					type: 'USER_MESSEGE',
					payload: {
						open: true,
						text: 'Código Inválido',
						time: 5000,
						type: 'error'
					}
				}]);
				setTimeout(() => {
					dispatch([{
						type: 'USER_MESSEGE',
						payload: {
							open: false,
							text: '',
							time: 5000,
							type: 'error'
						}
					}]);
				}, 6000);
				return;
			}
		}

		axios.post(url, values)
		.then(resp => {
			dispatch([
				{type: 'USER_FETCHED', payload: resp.data}
			]);
		})
		.catch(e => {
			console.log(e);
			if (e.response.status == 400) {
				if (e.response.data instanceof Array) {
					e.response.data.forEach(retorno => {
						dispatch([{
							type: 'USER_MESSEGE',
							payload: {
								open: true,
								text: retorno.msg ? retorno.msg : retorno,
								time: 5000,
								type: 'error'
							}
						}]);
						setTimeout(() => {
							dispatch([{
								type: 'USER_MESSEGE',
								payload: {
									open: false,
									text: '',
									time: 5000,
									type: 'error'
								}
							}]);
						}, 6000);
					});
				} else {
					dispatch([{
						type: 'USER_MESSEGE',
						payload: {
							open: true,
							text: e.response.data,
							time: 5000,
							type: 'error'
						}
					}]);
					setTimeout(() => {
						dispatch([{
							type: 'USER_MESSEGE',
							payload: {
								open: false,
								text: '',
								time: 5000,
								type: 'error'
							}
						}]);
					}, 6000);
				}
			} else {
				dispatch([{
					type: 'USER_MESSEGE',
					payload: {
						open: true,
						text: 'Erro ao realizar Operação!!',
						time: 5000,
						type: 'error'
					}
				}]);
				setTimeout(() => {
					dispatch([{
						type: 'USER_MESSEGE',
						payload: {
							open: false,
							text: '',
							time: 5000,
							type: 'error'
						}
					}]);
				}, 6000);
			}
		});
	}
}

export function logout() {
	return {type: 'TOKEN_VALIDATED', payload: false};
}

export function validateToken(token) {
	return dispatch => {
		if (token) {
			axios.post(`${consts.OAPI_URL}/usuario/validateToken`, {token})
			.then(resp => {
				dispatch({type: 'TOKEN_VALIDATED', payload: resp.data.valid})
			})
			.catch(e => dispatch({type: 'TOKEN_VALIDATED', payload: false}))
		} else {
			dispatch({type: 'TOKEN_VALIDATED', payload: false});
		}
	}
}

export function buscarSessao() {

	return (dispatch, getState) => {

		axios.get(`${consts.API_URL}/usuario/sessao?
			id=${getState().auth && getState().auth.usuario && getState().auth.usuario.id ? getState().auth.usuario.id : null}`)
		.then(resp => {
			dispatch({
				type: 'USER_SESSAO',
				payload: resp.data
			});
		}).catch(ex => {
			console.log(ex);
		});
    };
}

export function esqueciSenha(registro) {
    return (dispatch, getState) => {
		axios.post(`${consts.OAPI_URL}/usuario/esqueciSenha`, {
			...registro
		}).then(resp => {
			dispatch([{
				type: 'USER_MESSEGE',
				payload: {
					open: true,
					text: 'Verifique seu e-mail',
					time: 5000,
					type: 'success'
				}
			}]);
			dispatch(setModoTela('alterarSenha'));
			dispatch(initForm({
				...registro
			}));
		}).catch(e => {
			if (e.response.status == 400) {
				if (e.response.data instanceof Array) {
					e.response.data.forEach(retorno => {
						dispatch([{
							type: 'USER_MESSEGE',
							payload: {
								open: true,
								text: retorno.msg ? retorno.msg : retorno,
								time: 5000,
								type: 'error'
							}
						}]);
						setTimeout(() => {
							dispatch([{
								type: 'USER_MESSEGE',
								payload: {
									open: false,
									text: '',
									time: 5000,
									type: 'error'
								}
							}]);
						}, 6000);
					});
				} else {
					dispatch([{
						type: 'USER_MESSEGE',
						payload: {
							open: true,
							text: e.response.data,
							time: 5000,
							type: 'error'
						}
					}]);
					setTimeout(() => {
						dispatch([{
							type: 'USER_MESSEGE',
							payload: {
								open: false,
								text: '',
								time: 5000,
								type: 'error'
							}
						}]);
					}, 6000);
				}
			} else {
				dispatch([{
					type: 'USER_MESSEGE',
					payload: {
						open: true,
						text: 'Erro ao realizar Operação!!',
						time: 5000,
						type: 'error'
					}
				}]);
				setTimeout(() => {
					dispatch([{
						type: 'USER_MESSEGE',
						payload: {
							open: false,
							text: '',
							time: 5000,
							type: 'error'
						}
					}]);
				}, 6000);
			}
		});
    };
}
