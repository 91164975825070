const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaEsporte: [],
	listaHoras: [],
	listaTipo: [{ id: 1, valor: 'Aula' }, { id: 2, valor: 'Jogo' }, { id: 3, valor: 'Aula e Jogo' }]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'QUADRA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'QUADRA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'QUADRA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'QUADRA_ESPORTE_SELECT_LISTADO':
            return {
				...state,
				listaEsporte: action.payload.data
			};

		case 'QUADRA_HORAS_SELECT_LISTADO':
            return {
				...state,
				listaHoras: action.payload
			};

        default:
            return state;
    }
}
