import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './agendaForm';
import imagemSoLogo from '../assets/images/sologo.png';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

import Calendario from '../common/calendario/calendario';

import {
	setModoTela, initForm, salvar, excluir, alterarVisto, getLista, getListaNotificacao, getListaEsporte, getListaProfessor, getListaQuadra,
	getListaHoras, getListaUsuario, getListaHorariosAgendados, gerarPdf
} from './agendaActions';

class Agenda extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaNotificacao();
		this.props.getListaEsporte();
		this.props.getListaProfessor();
		this.props.getListaQuadra();
		this.props.getListaHoras();
		this.props.getListaUsuario();

		setInterval(() => {
			// this.props.getLista();
			this.props.getListaNotificacao();
		// 	this.props.getListaEsporte();
		// 	this.props.getListaQuadra();
			this.props.getListaHoras();
			this.props.getListaUsuario();
		}, 30000);
    }

    render() {

        return (
            <div>

				{/*this.props.aguardando ? (
					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', paddingRight: 15 }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ marginLeft: '50%', marginRight: '50%' }}>
									<img src={imagemSoLogo} style={{ height: 40, width: 40, marginBottom: -76, marginLeft: 8 }} />
								</div>
								<i class='fas fa-4x fa-spinner fa-spin' style={{ marginLeft: '50%', marginRight: '50%' }}></i>
							</div>
						</div>
					</div>
				) : null*/}

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let diaSemana = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

		let listaAgenda = this.props.lista.filter(item => {
			if (this.props.sessao && this.props.sessao.id_professor) {
				return item.id_professor == this.props.sessao.id_professor;
			} else {
				return true;
			}
		}).map(item => {
			let info = null;
			return {
				id: item.id,
				data: item.data_agenda,
				horas: item.horarios,
				titulo: `${item.nome_jogador} - ${item.aula ? 'aula' : 'jogo'}${item.nome_professor ? ' com ' : ''}${item.nome_professor || ''}`,
				descricao:
					<div>

					</div>,
				evento: () => {
					if (this.props.sessao && !this.props.sessao.id_professor) {
						this.props.setModoTela('exclusao', { ...item });
						this.props.initForm({
							...item
						});
					}
				},
				id_grupo: item.id_quadra,
				diaTodo: false,
				corFundo: item.id_agenda_status == 1 ? (item.aula ? '#a102d1' : '#0296d1') : (item.id_agenda_status == 2 ? '#4caf50' : '#e53935'),
				corFonte: '#ffffff'
			};
		});

		return (
			<>
				<ContentCard>

					<ContentCardHeader>
						<Row>
							{this.props.sessao && !this.props.sessao.id_professor ? (
								<Grid cols='6 6 4 2'>
									<Button
										text='Adicionar'
										type={'success'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.setModoTela('cadastro', {});
											this.props.initForm({
												data_agenda: DateFormat.getDataAtual(),
												horarios: [],
												id_agenda_status: 1 //Agendado
											});
											this.props.getListaHorariosAgendados(DateFormat.getDataAtual());
										}} />
								</Grid>
							) : null}
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						{this.props.sessao.acesso_administrador && !this.props.sessao.id_professor ? (
							<Row>
								{this.props.listaNotificacao.map(item => {
									return (
										<Grid key={item.id} cols='12 6 4 4'>
											<div class={`callout callout-${item.ativo ? 'success' : 'danger'}`}>
												<button
													type='button'
													class='close'
													data-dismiss='alert'
													aria-hidden='true'
													onClick={() => this.props.alterarVisto(item)}>×</button>
												<h6>{item.mensagem_notificacao}</h6>
												<div>Data: {item.dia_semana} - {item.data_formatada} ({item.horarios.join(' e ')})</div>
												<div>Quadra: {item.nome_quadra}</div>
												<div>Nome: {item.nome_jogador}</div>
												<div>Tipo: {`${item.aula ? 'aula' : 'jogo'}${item.nome_professor ? ' com ' : ''}${item.nome_professor || ''}`}</div>
											</div>
										</Grid>
									);
								})}
							</Row>
						) : null}

						<Calendario
							atualizarLista={data => this.props.getLista(data)}
							dados={listaAgenda}
							grupos={this.props.listaQuadra.filter(item => {
								if (this.props.sessao && this.props.sessao.id_professor) {
									let professor = this.props.listaProfessor.filter(professor => professor.id == this.props.sessao.id_professor)[0];
									return professor && item.id == professor.id_quadra;
								} else {
									return true;
								}
							}).map(item => ({ id: item.id, nome: `${item.nome} - ${item.descricao}` }))}
							imprimir={(data, tipo) => this.props.gerarPdf(data, tipo)}
						/>
					</ContentCardBody>
				</ContentCard>
			</>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.agenda.modoTela,
	listaNotificacao: state.agenda.listaNotificacao,
	lista: state.agenda.lista,
	listaQuadra: state.agenda.listaQuadra,
	listaProfessor: state.agenda.listaProfessor,
	aguardando: state.agenda.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, excluir, alterarVisto, getLista, getListaNotificacao, getListaEsporte, getListaProfessor, getListaQuadra,
	getListaHoras, getListaUsuario, getListaHorariosAgendados, gerarPdf
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
