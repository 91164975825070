import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'COMPETICAO_INSCRICAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('competicaoInscricaoForm', {
			...registro,
			
				
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'COMPETICAO_INSCRICAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/competicaoInscricao`);
    return {
        type: 'COMPETICAO_INSCRICAO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
	console.log(registro)
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/competicaoInscricao`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
				
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			console.log(registro)
			axios.put(`${consts.API_URL}/competicaoInscricao`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/competicaoInscricao?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}


export function getListaCompeticao() {
    const request = axios.get(`${consts.API_URL}/competicao/listarSelect`);
    return {
        type: 'COMPETICAO_CATEGORIA_COMPETICAO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCompeticaoCategoria(id_competicao) {
    const request = axios.get(`${consts.API_URL}/competicaoCategoria/listarSelect?id_competicao=${id_competicao}`);
    return {
        type: 'COMPETICAO_INSCRICAO_COMPETICAO_CATEGORIA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCompeticaoImpedimento() {
    const request = axios.get(`${consts.API_URL}/competicaoImpedimento/listarSelect`);
    return {
        type: 'COMPETICAO_INSCRICAO_COMPETICAO_IMPEDIMENTO_SELECT_LISTADO',
        payload: request
    };
}