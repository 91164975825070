import React, { useEffect } from 'react';

import OneSignal from 'react-onesignal';

// import firebase from './firebase';

import AuthOrApp from './main/authOrApp'

export default function App() {

	useEffect(() => {
		OneSignal.init({
			appId: '9fae5f05-35d1-4ea7-9526-ab3c36632599'
		});
	}, []);

	return (
		<AuthOrApp />
	);
}
