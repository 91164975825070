import React, { Component } from 'react';
import Row from '../layout/row';
import Button from '../button/button';
import { getDataAtual, getDataFormatada, getData } from '../../utils/dataUtils';

class Calendario extends Component {

	constructor(props) {
        super(props)
        this.state = {
			modoAgenda: 'semana',
			dataAgenda: getDataAtual(),
			itemModal: null
		};
        this.eventoModal = this.eventoModal.bind(this);
    }

	render() {

		let styleDivPrincipal = {
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'wrap',
			marginBottom: 20,
			overflowX: 'auto'
		};

		let styleDivAgenda = {
			margin: 4
		};

		let styleDivBox = {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'left',
			alignItems: 'center',
			height: 40,
			fontWeight: 'bold',
			fontSize: 18
		};

		let styleDivControleButton = {
			backgroundColor: 'Transparent',
			backgroundRepeat: 'no-repeat',
			border: 'none',
			cursor: 'pointer',
			overflow: 'hidden',
			padding: 8,
			color: '#444'
		}

		let styleDivControleModo = {
			width: 80,
			padding: 0,
			fontWeight: 'bold'
		};

		let dataInicial = new Date(`${getDataFormatada(this.state.dataAgenda, 'YYYY')}/${getDataFormatada(this.state.dataAgenda, 'MM')}/${getDataFormatada(this.state.dataAgenda, 'DD')} 12:00`);
		if (this.state.modoAgenda == 'semana') {
			dataInicial.setDate(dataInicial.getDate() - dataInicial.getDay());
		}
		let ano = parseInt(getDataFormatada(dataInicial, 'YYYY'));
		let mesPos = parseInt(getDataFormatada(dataInicial, 'MM')) - 1;
		let dia = parseInt(getDataFormatada(dataInicial, 'DD'));
		let diaFinalSemana = null;
		let mesPosFinalSemana = null;
		let anoFinalSemana = null;
		if (this.state.modoAgenda == 'semana') {
			let dataFinal = dataInicial;
			dataFinal.setDate(dataFinal.getDate() + 6);
			anoFinalSemana = parseInt(getDataFormatada(dataFinal, 'YYYY'));
			mesPosFinalSemana = parseInt(getDataFormatada(dataFinal, 'MM')) - 1;
			diaFinalSemana = parseInt(getDataFormatada(dataFinal, 'DD'));
		}

		let anos = [];
		for (var i = 1970; i <= 2100; i++) {
			anos.push(i);
		}
		let meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

		return (
			<div style={styleDivPrincipal}>
				<div style={styleDivAgenda}>
					<div style={styleDivBox}>
						{/*<select
							style={styleDivControleModo}
							class="form-control"
							value={this.state.modoAgenda}
							onChange={(data) => {
								this.setState({ modoAgenda: data.target.value });
							}}>
							<option style={styleDivControleModo} value='dia'>Dia</option>
							<option style={styleDivControleModo} value='semana'>Semana</option>
							<option style={styleDivControleModo} value='mes'>Mês</option>
						</select>*/}
						<a style={styleDivControleButton}
							onClick={() => {
								let dataAgenda = new Date(this.state.dataAgenda || ' 12:00');
								if (this.state.modoAgenda == 'dia') {
									dataAgenda.setDate(dataAgenda.getDate() - 1);
								} else if (this.state.modoAgenda == 'semana') {
									dataAgenda.setDate(dataAgenda.getDate() - 7);
								} else if(this.state.modoAgenda == 'mes') {
									dataAgenda.setMonth(dataAgenda.getMonth() - 1);
								}
								this.setState({ dataAgenda: dataAgenda });
								this.props.atualizarLista(getDataFormatada(dataAgenda, 'YYYY-MM-DD'));
							}}>
							<i class='fa fa-chevron-left'></i>
						</a>
						<a style={styleDivControleButton}
							onClick={() => {
								let dataAgenda = new Date(this.state.dataAgenda || ' 12:00');
								if (this.state.modoAgenda == 'dia') {
									dataAgenda.setDate(dataAgenda.getDate() + 1);
								} else if (this.state.modoAgenda == 'semana') {
									dataAgenda.setDate(dataAgenda.getDate() + 7);
								} else if(this.state.modoAgenda == 'mes') {
									dataAgenda.setMonth(dataAgenda.getMonth() + 1);
								}
								this.setState({ dataAgenda: dataAgenda });
								this.props.atualizarLista(getDataFormatada(dataAgenda, 'YYYY-MM-DD'));
							}}>
								<i class='fa fa-chevron-right'></i>
							</a>
						{this.state.modoAgenda == 'dia' ? (
							`${dia} de ${meses[mesPos]} de ${ano}`
						) : this.state.modoAgenda == 'semana' ? (
							mesPos == mesPosFinalSemana
								? `${dia} até ${diaFinalSemana} de ${meses[mesPos]} de ${ano}`
								: ano == anoFinalSemana
									? `${dia} de ${meses[mesPos]} até ${diaFinalSemana} de ${meses[mesPosFinalSemana]} de ${ano}`
									: `${dia} de ${meses[mesPos]} de ${ano} até ${diaFinalSemana} de ${meses[mesPosFinalSemana]} de ${anoFinalSemana}`
						) : (
							`${meses[mesPos]} de ${ano}`
						)}
						{/*}<a style={styleDivControleButton}
							onClick={() => {
								let dataBase = new Date(`${getDataFormatada(this.state.dataAgenda, 'YYYY')}/${getDataFormatada(this.state.dataAgenda, 'MM')}/${getDataFormatada(this.state.dataAgenda, 'DD')} 12:00`);
								dataBase.setDate(dataBase.getDate() - dataBase.getDay());

								for (var i = 0; i < 7; i++) {
									this.props.imprimir(`${getDataFormatada(dataBase, 'YYYY')}/${getDataFormatada(dataBase, 'MM')}/${getDataFormatada(dataBase, 'DD')} 12:00`);
									dataBase.setDate(dataBase.getDate() + 1);
								}
							}}>
							<i class='fas fa-print'></i>
						</a>*/}
					</div>
					{this.state.modoAgenda == 'dia' ? this.modoDia() : null}
					{this.state.modoAgenda == 'semana' ? this.modoSemana() : null}
					{this.state.modoAgenda == 'mes' ? this.modoMes() : null}
				</div>
			</div>
		);
	}

	modoDia() {

		let styleDivSemanas = {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'inherit'
		};

		let styleDivDiaSemana = {
			display: 'flex',
			flex: 7,
			flexDirection: 'row',
			justifyContent: 'center',
			fontWeight: 'bold'
		};

		let styleDivDia = {
			minHeight: 100,
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			justifyContent: 'top',
			alignItems: 'stretch',
			cursor: 'pointer',
			border: '1px solid #e0e0e0'
		};

		let styleDivDiaItemLabel = {
			marginLeft: 8,
			marginTop: 4
		};

		let ano = parseInt(getDataFormatada(this.state.dataAgenda, 'YYYY'));
		let mesPos = parseInt(getDataFormatada(this.state.dataAgenda, 'MM')) - 1;
		let dia = parseInt(getDataFormatada(this.state.dataAgenda, 'DD'));
		let diaSemana = parseInt(new Date(getDataFormatada(this.state.dataAgenda, 'YYYY/MM/DD 12:00')).getDay());

		let anos = [];
		for (var i = 1970; i <= 2100; i++) {
			anos.push(i);
		}
		let meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
		let diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
		let diasSemanaCompleto = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

		let agrupadores = [];
		this.props.dados.filter(itemAgenda => getDataFormatada(itemAgenda.data, 'YYYY/MM/DD') == `${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? '0' : ''}${dia}`)
		.forEach(item => {
			let corFundo = item.corFundo || '#0073b7';
			let corFonte = item.corFonte || '#ffffff';
			let agrupador = agrupadores.filter(agrupador => agrupador.corFundo == corFundo)[0];
			if (!agrupador) {
				agrupador = {
					corFundo: corFundo,
					corFonte: corFonte,
					quantidade: 0
				};
				agrupadores.push(agrupador);
			}
			agrupador.quantidade++;
		});

		return (
			<div key={dia} style={styleDivDia}
				onClick={() => {
					if (this.props.dataSelecionada) {
						this.props.dataSelecionada(new Date(`${ano}/${mesPos + 1 < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? 0 : ''}${dia}`));
					}
				}}>
				<label style={styleDivDiaItemLabel}>
					{diasSemanaCompleto[diaSemana]}
				</label>
				<div style={styleDivDiaItemLabel}>
					{agrupadores.map(agrupador => (
						<span key={agrupador.corFundo} class='pull-right badge'
							style={{ backgroundColor: agrupador.corFundo, color: agrupador.corFonte }}>
							{agrupador.quantidade}
						</span>
					))}
				</div>
				{this.props.dados.filter(itemAgenda => getDataFormatada(itemAgenda.data, 'YYYY/MM/DD') == `${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? '0' : ''}${dia}`)
				.map((item, i) => (
					this.montarItem({
						...item,
						dia: dia,
						mes: meses[mesPos],
						ano: ano,
						diaSemana: diasSemanaCompleto[new Date(getDataFormatada(item.data, 'YYYY/MM/DD')).getDay()]
					})
				))}
			</div>
		);
	}

	modoSemana() {

		let styleDivSemanas = {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'inherit'
		};

		let styleDivDiaSemana = {
			display: 'flex',
			flex: 7,
			flexDirection: 'row',
			justifyContent: 'center',
			fontWeight: 'bold'
		};

		let styleDivDia = {
			minHeight: 100,
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			justifyContent: 'top',
			alignItems: 'stretch',
			cursor: 'pointer',
			border: '1px solid #e0e0e0'
		};

		let styleDivDiaItemLabel = {
			marginLeft: 4,
			marginRight: 4,
			paddingLeft: 4,
			paddingRight: 4,
			marginTop: 4
		};

		let styleDivDiaItemLabelAtual = {
			marginLeft: 4,
			marginRight: 4,
			paddingLeft: 4,
			paddingRight: 4,
			marginTop: 4,
			color: '#d32f2f'
		};

		let styleDivControleButton = {
			backgroundColor: 'Transparent',
			backgroundRepeat: 'no-repeat',
			border: 'none',
			cursor: 'pointer',
			overflow: 'hidden',
			padding: 8,
			color: '#444'
		}

		let dataInicial = new Date(`${getDataFormatada(this.state.dataAgenda, 'YYYY')}/${getDataFormatada(this.state.dataAgenda, 'MM')}/${getDataFormatada(this.state.dataAgenda, 'DD')} 12:00`);
		dataInicial.setDate(dataInicial.getDate() - dataInicial.getDay());
		let ano = parseInt(getDataFormatada(dataInicial, 'YYYY'));
		let mesPos = parseInt(getDataFormatada(dataInicial, 'MM')) - 1;
		let dia = parseInt(getDataFormatada(dataInicial, 'DD'));

		let anos = [];
		for (var i = 1970; i <= 2100; i++) {
			anos.push(i);
		}
		let meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
		let diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
		let diasSemanaCompleto = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

		let arrayDias = [];
		for (let i = 1; i <= 7; i++) {
			arrayDias.push({
				ano: parseInt(getDataFormatada(dataInicial, 'YYYY')),
				mesPos: parseInt(getDataFormatada(dataInicial, 'MM')) - 1,
				dia: parseInt(getDataFormatada(dataInicial, 'DD'))
			});
			dataInicial.setDate(dataInicial.getDate() + 1);
		}
		return (
			<div>
				{(this.props.grupos || [{ id: null }]).map(grupo => (
					<div>
						<h6 style={{ paddingTop: 8 }}>{grupo.nome}</h6>
						<div style={styleDivSemanas}>
							{arrayDias.map(({ dia, mesPos, ano}) => {
								let agrupadores = [];
								this.props.dados.filter(itemAgenda => (!itemAgenda.id_grupo || itemAgenda.id_grupo == grupo.id) && getDataFormatada(itemAgenda.data, 'YYYY/MM/DD') == `${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? '0' : ''}${dia}`)
								.forEach(item => {
									let corFundo = item.corFundo || '#0073b7';
									let corFonte = item.corFonte || '#ffffff';
									let agrupador = agrupadores.filter(agrupador => agrupador.corFundo == corFundo)[0];
									if (!agrupador) {
										agrupador = {
											corFundo: corFundo,
											corFonte: corFonte,
											quantidade: 0
										};
										agrupadores.push(agrupador);
									}
									agrupador.quantidade++;
								});

								return (
									<div key={dia} style={styleDivDia}
										onClick={() => {
											if (this.props.dataSelecionada) {
												this.props.dataSelecionada(new Date(`${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? 0 : ''}${dia}`));
											}
										}}>
										<label style={new Date().getDate() == dia ? styleDivDiaItemLabelAtual : styleDivDiaItemLabel}>
											{diasSemana[new Date(`${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? '0' : ''}${dia} 12:00`).getDay()]}. {dia}
											<a style={styleDivControleButton}
												onClick={() => {
													this.props.imprimir(`${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? 0 : ''}${dia} 12:00`, 'jogo');

												}}>
												<i class='fas fa-print'></i>
											</a>
											<a style={styleDivControleButton}
												onClick={() => {
													this.props.imprimir(`${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? 0 : ''}${dia} 12:00`, 'aula');

												}}>
												<i class='fas fa-user-graduate'></i>
											</a>
										</label>
										{dia ? (
											<div>
												{agrupadores.map(agrupador => (
													<span key={agrupador.corFundo} class='pull-right badge'
														style={{ backgroundColor: agrupador.corFundo, color: agrupador.corFonte }}>
														{agrupador.quantidade}
													</span>
												))}
											</div>
										) : null}
										{dia ? (
											this.props.dados.filter(itemAgenda => (!itemAgenda.id_grupo || itemAgenda.id_grupo == grupo.id) && getDataFormatada(itemAgenda.data, 'YYYY/MM/DD') == `${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? '0' : ''}${dia}`)
											.map((item, i) => (
												this.montarItem({
													...item,
													dia: dia,
													mes: meses[mesPos],
													ano: ano,
													diaSemana: diasSemanaCompleto[new Date(getDataFormatada(item.data, 'YYYY/MM/DD')).getDay()]
												})
											))
										) : null}
									</div>
								);
							})}
						</div>
					</div>
				))}
			</div>
		);
	}

	modoMes() {

		let styleDivSemanas = {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'inherit'
		};

		let styleDivDiaSemana = {
			display: 'flex',
			flex: 7,
			flexDirection: 'row',
			justifyContent: 'center',
			fontWeight: 'bold'
		};

		let styleDivDia = {
			minHeight: 100,
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			justifyContent: 'top',
			alignItems: 'stretch',
			cursor: 'pointer',
			border: '1px solid #e0e0e0'
		};

		let styleDivDiaItemLabel = {
			marginLeft: 4,
			marginRight: 4,
			paddingLeft: 4,
			paddingRight: 4,
			marginTop: 4
		};

		let styleDivDiaItemLabelAtual = {
			marginLeft: 4,
			marginRight: 4,
			paddingLeft: 4,
			paddingRight: 4,
			marginTop: 4,
			color: '#d32f2f'
		};

		let ano = parseInt(getDataFormatada(this.state.dataAgenda, 'YYYY'));
		let mesPos = parseInt(getDataFormatada(this.state.dataAgenda, 'MM')) - 1;

		let anos = [];
		for (var i = 1970; i <= 2100; i++) {
			anos.push(i);
		}
		let meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
		let diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
		let diasSemanaCompleto = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

		let dataInicial = new Date(`${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/01 12:00`);
		let dataFinal = new Date(`${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/01 12:00`);
		dataFinal.setMonth(dataFinal.getMonth() + 1);
		dataFinal.setDate(dataFinal.getDate() - 1);

		let matrizDias = [];
		let semana = 0;
		for (let diaPos = 1; diaPos <= dataFinal.getDate(); diaPos++) {
			if (!matrizDias[semana]) {
				matrizDias.push([null, null, null, null, null, null, null]);
			}
			let data = new Date(`${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${diaPos < 10 ? '0' : ''}${diaPos} 12:00`);

			matrizDias[semana][data.getDay()] = diaPos;

			if (data.getDay() == 6) {
				semana++;
			}
		}

		return (
			<div>
				<div style={styleDivSemanas}>
					{diasSemana.map((diaSemana, i) => {
						return (
							<div style={styleDivDiaSemana}>
								{diaSemana}
							</div>
						);
					})}
				</div>
				{matrizDias.map(arraySemana => (
					<div style={styleDivSemanas}>
						{arraySemana.map((dia, diaPos) => {

							let agrupadores = [];
							this.props.dados.filter(itemAgenda => getDataFormatada(itemAgenda.data, 'YYYY/MM/DD') == `${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? '0' : ''}${dia}`)
							.forEach(item => {
								let corFundo = item.corFundo || '#0073b7';
								let corFonte = item.corFonte || '#ffffff';
								let agrupador = agrupadores.filter(agrupador => agrupador.corFundo == corFundo)[0];
								if (!agrupador) {
									agrupador = {
										corFundo: corFundo,
										corFonte: corFonte,
										quantidade: 0
									};
									agrupadores.push(agrupador);
								}
								agrupador.quantidade++;
							});

							return (
								<div key={diaPos} style={styleDivDia}
									onClick={() => {
										if (this.props.dataSelecionada) {
											this.props.dataSelecionada(new Date(`${ano}/${mesPos + 1 < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? 0 : ''}${dia}`));
										}
									}}>
									<label style={getDataFormatada(new Date(), 'YYYY/MM/DD') == `${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? '0' : ''}${dia}` ? styleDivDiaItemLabelAtual : styleDivDiaItemLabel}>
										{dia}
									</label>
									{dia ? (
										<div>
											{agrupadores.map(agrupador => (
												<span key={agrupador.corFundo} class='pull-right badge'
													style={{ backgroundColor: agrupador.corFundo, color: agrupador.corFonte }}>
													{agrupador.quantidade}
												</span>
											))}
										</div>
									) : null}
									{dia ? (
										this.props.dados.filter(itemAgenda => getDataFormatada(itemAgenda.data, 'YYYY/MM/DD') == `${ano}/${(mesPos + 1) < 10 ? '0' : ''}${mesPos + 1}/${dia < 10 ? '0' : ''}${dia}`)
										.map((item, i) => (
											this.montarItem({
												...item,
												dia: dia,
												mes: meses[mesPos],
												ano: ano,
												diaSemana: diasSemanaCompleto[new Date(getDataFormatada(item.data, 'YYYY/MM/DD')).getDay()]
											})
										))
									) : null}
								</div>
							);
						})}
					</div>
				))}
			</div>
		);
	}

	montarItem(item) {
		let styleDivDiaItem = {
			backgroundColor: item.corFundo || '#0073b7',
			color: item.corFonte || '#ffffff',
			padding: 2,
			margin: 2
		};

		let styleDivModalHead = {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end'
		};

		let styleDivModalHeadButtom = {
			padding: 8,
			color: '#444'
		};

		let styleDivModalBody = {
			color: '#444'
		};

		let styleDivModalBodyTitulo = {
			fontSize: 18,
			fontWeight: 'bold'
		};

		let divTitulo ={
			display: 'flex',
			alignItems: 'center'
		};

		let divTituloHora = {
			display: 'flex',
			flexDirection: 'column',
			marginRight: 8
		};

		let divTituloLabelHora = {
			padding: 0,
			margin: 0
		};

		let divTituloLabeTexto = {
			fontWeight: 'bold',
			padding: 0,
			margin: 0
		};

		return (
			<div key={item.id} style={styleDivDiaItem}>
				<div onClick={() => {
						item.evento();
						// this.eventoModal(item.id);
					}}>
					{item.horas ? (
						<div style={divTitulo}>
							<div style={divTituloHora}>
								{item.horas.map(hora => (
									<p style={divTituloLabelHora}>{hora}</p>
								))}
							</div>
							<p style={divTituloLabeTexto}>{item.titulo}</p>
						</div>
					) : (
						<p>{item.titulo}</p>
					)}
				</div>
				{this.state.itemModal && this.state.itemModal == item.id ? (
					<div class="modal fade in" id="modal-default" style={{ display: 'block', paddingRight: 15 }}>
						<div class="modal-dialog" style={{ top: '50%', transform: 'translateY(-50%)' }}>
							<div class="modal-content">
								<div class="modal-body">
									<div style={styleDivModalHead}>
										<i class='fa fa-pencil'
											style={styleDivModalHeadButtom}
											aria-hidden='true'
											onClick={() => {
												if (item.evento) {
													item.evento();
												}
											}}>
										</i>
										<i class='fa fa-times'
											style={styleDivModalHeadButtom}
											aria-hidden='true'
											onClick={() => {
												this.setState({ ...this.state, itemModal: null });
											}}>
										</i>
									</div>
									<div style={styleDivModalBody}>
										<p style={styleDivModalBodyTitulo}>{item.titulo}</p>
										{item.diaTodo ? (
											<p>{item.diaSemana}, {item.dia} de {item.mes} - Todo o Dia</p>
										) : (
											<div>
												<p>{item.diaSemana}, {item.dia} de {item.mes} - {item.horaInicio} até {item.horaFim}</p>
											</div>
										)}
										{item.descricao}
									</div>
								</div>
							</div>
						</div>
			        </div>
				) : null}
			</div>
		);
	}

	eventoModal(itemAgenda) {
		this.setState({ itemModal: itemAgenda });
	}
}

export default Calendario;
